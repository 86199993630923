html {
  font: normal 14px / 20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background: #272b30;
  color: #c8c8c8;
}
body {
  margin: 0;
}
a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}
a,
.uk-link {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
.uk-link:hover {
  color: #ffffff;
  text-decoration: underline;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  font-size: 12px;
  font-family: Consolas, monospace, serif;
  color: #c7254e;
  white-space: nowrap;
}
em {
  color: #c8c8c8;
}
ins {
  background: rgba(255, 255, 255, 0);
  color: #c8c8c8;
  text-decoration: none;
}
mark {
  background: #f89406;
  color: #000000;
}
q {
  font-style: italic;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
img {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  border: 0;
}
.uk-img-preserve,
.uk-img-preserve img {
  max-width: none;
}
svg:not(:root) {
  overflow: hidden;
}
blockquote,
figure {
  margin: 0;
}
p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0 0 15px 0;
}
* + p,
* + ul,
* + ol,
* + dl,
* + blockquote,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #c8c8c8;
  text-transform: none;
}
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 25px;
}
h1,
.uk-h1 {
  font-size: 36px;
  line-height: 42px;
}
h2,
.uk-h2 {
  font-size: 24px;
  line-height: 30px;
}
h3,
.uk-h3 {
  font-size: 18px;
  line-height: 24px;
}
h4,
.uk-h4 {
  font-size: 16px;
  line-height: 22px;
}
h5,
.uk-h5 {
  font-size: 14px;
  line-height: 20px;
}
h6,
.uk-h6 {
  font-size: 12px;
  line-height: 18px;
}
ul,
ol {
  padding-left: 30px;
}
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  margin: 15px 0;
  border: 0;
  border-top: 1px solid #141619;
}
address {
  font-style: normal;
}
blockquote {
  padding-left: 15px;
  border-left: 5px solid #141619;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}
pre {
  padding: 10px;
  background: #b3b1b1;
  font: 12px / 18px Consolas, monospace, serif;
  color: #3a3f44;
  -moz-tab-size: 4;
  tab-size: 4;
  overflow: auto;
}
::-moz-selection {
  background: #1a48cd;
  color: #ffffff;
  text-shadow: none;
}
::selection {
  background: #1a48cd;
  color: #ffffff;
  text-shadow: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
progress {
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
}
[hidden],
template {
  display: none;
}
iframe {
  border: 0;
}
@media screen and (max-width: 400px) {
  @-ms-viewport {
    width: device-width;
  }
}
.uk-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.uk-grid:before,
.uk-grid:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-grid:after {
  clear: both;
}
.uk-grid > * {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  margin: 0;
  float: left;
}
.uk-grid > * > :last-child {
  margin-bottom: 0;
}
.uk-grid {
  margin-left: -25px;
}
.uk-grid > * {
  padding-left: 25px;
}
.uk-grid + .uk-grid,
.uk-grid-margin,
.uk-grid > * > .uk-panel + .uk-panel {
  margin-top: 25px;
}
@media (min-width: 1220px) {
  .uk-grid {
    margin-left: -35px;
  }
  .uk-grid > * {
    padding-left: 35px;
  }
  .uk-grid + .uk-grid,
  .uk-grid-margin,
  .uk-grid > * > .uk-panel + .uk-panel {
    margin-top: 35px;
  }
}
.uk-grid-collapse {
  margin-left: 0;
}
.uk-grid-collapse > * {
  padding-left: 0;
}
.uk-grid-collapse + .uk-grid-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-collapse > * > .uk-panel + .uk-panel {
  margin-top: 0;
}
.uk-grid-small {
  margin-left: -10px;
}
.uk-grid-small > * {
  padding-left: 10px;
}
.uk-grid-small + .uk-grid-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-small > * > .uk-panel + .uk-panel {
  margin-top: 10px;
}
.uk-grid-medium {
  margin-left: -25px;
}
.uk-grid-medium > * {
  padding-left: 25px;
}
.uk-grid-medium + .uk-grid-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-medium > * > .uk-panel + .uk-panel {
  margin-top: 25px;
}
.uk-grid-divider:not(:empty) {
  margin-left: -25px;
  margin-right: -25px;
}
.uk-grid-divider > * {
  padding-left: 25px;
  padding-right: 25px;
}
.uk-grid-divider > [class*='uk-width-1-']:not(.uk-width-1-1):nth-child(n+2),
.uk-grid-divider > [class*='uk-width-2-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-3-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-4-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-5-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-6-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-7-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-8-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-9-']:nth-child(n+2) {
  border-left: 1px solid #141619;
}
@media (min-width: 768px) {
  .uk-grid-divider > [class*='uk-width-medium-']:not(.uk-width-medium-1-1):nth-child(n+2) {
    border-left: 1px solid #141619;
  }
}
@media (min-width: 960px) {
  .uk-grid-divider > [class*='uk-width-large-']:not(.uk-width-large-1-1):nth-child(n+2) {
    border-left: 1px solid #141619;
  }
}
@media (min-width: 1220px) {
  .uk-grid-divider:not(:empty) {
    margin-left: -35px;
    margin-right: -35px;
  }
  .uk-grid-divider > * {
    padding-left: 35px;
    padding-right: 35px;
  }
  .uk-grid-divider:empty {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
.uk-grid-divider:empty {
  margin-top: 25px;
  margin-bottom: 25px;
  border-top: 1px solid #141619;
}
.uk-grid-match > * {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.uk-grid-match > * > * {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  box-sizing: border-box;
  width: 100%;
}
[class*='uk-grid-width'] > * {
  box-sizing: border-box;
  width: 100%;
}
.uk-grid-width-1-2 > * {
  width: 50%;
}
.uk-grid-width-1-3 > * {
  width: 33.333%;
}
.uk-grid-width-1-4 > * {
  width: 25%;
}
.uk-grid-width-1-5 > * {
  width: 20%;
}
.uk-grid-width-1-6 > * {
  width: 16.666%;
}
.uk-grid-width-1-10 > * {
  width: 10%;
}
.uk-grid-width-auto > * {
  width: auto;
}
@media (min-width: 480px) {
  .uk-grid-width-small-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-small-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-small-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-small-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-small-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-small-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-small-1-10 > * {
    width: 10%;
  }
}
@media (min-width: 768px) {
  .uk-grid-width-medium-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-medium-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-medium-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-medium-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-medium-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-medium-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-medium-1-10 > * {
    width: 10%;
  }
}
@media (min-width: 960px) {
  .uk-grid-width-large-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-large-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-large-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-large-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-large-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-large-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-large-1-10 > * {
    width: 10%;
  }
}
@media (min-width: 1220px) {
  .uk-grid-width-xlarge-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-xlarge-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-xlarge-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-xlarge-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-xlarge-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-xlarge-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-xlarge-1-10 > * {
    width: 10%;
  }
}
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
}
.uk-width-1-1 {
  width: 100%;
}
.uk-width-1-2,
.uk-width-2-4,
.uk-width-3-6,
.uk-width-5-10 {
  width: 50%;
}
.uk-width-1-3,
.uk-width-2-6 {
  width: 33.333%;
}
.uk-width-2-3,
.uk-width-4-6 {
  width: 66.666%;
}
.uk-width-1-4 {
  width: 25%;
}
.uk-width-3-4 {
  width: 75%;
}
.uk-width-1-5,
.uk-width-2-10 {
  width: 20%;
}
.uk-width-2-5,
.uk-width-4-10 {
  width: 40%;
}
.uk-width-3-5,
.uk-width-6-10 {
  width: 60%;
}
.uk-width-4-5,
.uk-width-8-10 {
  width: 80%;
}
.uk-width-1-6 {
  width: 16.666%;
}
.uk-width-5-6 {
  width: 83.333%;
}
.uk-width-1-10 {
  width: 10%;
}
.uk-width-3-10 {
  width: 30%;
}
.uk-width-7-10 {
  width: 70%;
}
.uk-width-9-10 {
  width: 90%;
}
@media (min-width: 480px) {
  .uk-width-small-1-1 {
    width: 100%;
  }
  .uk-width-small-1-2,
  .uk-width-small-2-4,
  .uk-width-small-3-6,
  .uk-width-small-5-10 {
    width: 50%;
  }
  .uk-width-small-1-3,
  .uk-width-small-2-6 {
    width: 33.333%;
  }
  .uk-width-small-2-3,
  .uk-width-small-4-6 {
    width: 66.666%;
  }
  .uk-width-small-1-4 {
    width: 25%;
  }
  .uk-width-small-3-4 {
    width: 75%;
  }
  .uk-width-small-1-5,
  .uk-width-small-2-10 {
    width: 20%;
  }
  .uk-width-small-2-5,
  .uk-width-small-4-10 {
    width: 40%;
  }
  .uk-width-small-3-5,
  .uk-width-small-6-10 {
    width: 60%;
  }
  .uk-width-small-4-5,
  .uk-width-small-8-10 {
    width: 80%;
  }
  .uk-width-small-1-6 {
    width: 16.666%;
  }
  .uk-width-small-5-6 {
    width: 83.333%;
  }
  .uk-width-small-1-10 {
    width: 10%;
  }
  .uk-width-small-3-10 {
    width: 30%;
  }
  .uk-width-small-7-10 {
    width: 70%;
  }
  .uk-width-small-9-10 {
    width: 90%;
  }
}
@media (min-width: 768px) {
  .uk-width-medium-1-1 {
    width: 100%;
  }
  .uk-width-medium-1-2,
  .uk-width-medium-2-4,
  .uk-width-medium-3-6,
  .uk-width-medium-5-10 {
    width: 50%;
  }
  .uk-width-medium-1-3,
  .uk-width-medium-2-6 {
    width: 33.333%;
  }
  .uk-width-medium-2-3,
  .uk-width-medium-4-6 {
    width: 66.666%;
  }
  .uk-width-medium-1-4 {
    width: 25%;
  }
  .uk-width-medium-3-4 {
    width: 75%;
  }
  .uk-width-medium-1-5,
  .uk-width-medium-2-10 {
    width: 20%;
  }
  .uk-width-medium-2-5,
  .uk-width-medium-4-10 {
    width: 40%;
  }
  .uk-width-medium-3-5,
  .uk-width-medium-6-10 {
    width: 60%;
  }
  .uk-width-medium-4-5,
  .uk-width-medium-8-10 {
    width: 80%;
  }
  .uk-width-medium-1-6 {
    width: 16.666%;
  }
  .uk-width-medium-5-6 {
    width: 83.333%;
  }
  .uk-width-medium-1-10 {
    width: 10%;
  }
  .uk-width-medium-3-10 {
    width: 30%;
  }
  .uk-width-medium-7-10 {
    width: 70%;
  }
  .uk-width-medium-9-10 {
    width: 90%;
  }
}
@media (min-width: 960px) {
  .uk-width-large-1-1 {
    width: 100%;
  }
  .uk-width-large-1-2,
  .uk-width-large-2-4,
  .uk-width-large-3-6,
  .uk-width-large-5-10 {
    width: 50%;
  }
  .uk-width-large-1-3,
  .uk-width-large-2-6 {
    width: 33.333%;
  }
  .uk-width-large-2-3,
  .uk-width-large-4-6 {
    width: 66.666%;
  }
  .uk-width-large-1-4 {
    width: 25%;
  }
  .uk-width-large-3-4 {
    width: 75%;
  }
  .uk-width-large-1-5,
  .uk-width-large-2-10 {
    width: 20%;
  }
  .uk-width-large-2-5,
  .uk-width-large-4-10 {
    width: 40%;
  }
  .uk-width-large-3-5,
  .uk-width-large-6-10 {
    width: 60%;
  }
  .uk-width-large-4-5,
  .uk-width-large-8-10 {
    width: 80%;
  }
  .uk-width-large-1-6 {
    width: 16.666%;
  }
  .uk-width-large-5-6 {
    width: 83.333%;
  }
  .uk-width-large-1-10 {
    width: 10%;
  }
  .uk-width-large-3-10 {
    width: 30%;
  }
  .uk-width-large-7-10 {
    width: 70%;
  }
  .uk-width-large-9-10 {
    width: 90%;
  }
}
@media (min-width: 1220px) {
  .uk-width-xlarge-1-1 {
    width: 100%;
  }
  .uk-width-xlarge-1-2,
  .uk-width-xlarge-2-4,
  .uk-width-xlarge-3-6,
  .uk-width-xlarge-5-10 {
    width: 50%;
  }
  .uk-width-xlarge-1-3,
  .uk-width-xlarge-2-6 {
    width: 33.333%;
  }
  .uk-width-xlarge-2-3,
  .uk-width-xlarge-4-6 {
    width: 66.666%;
  }
  .uk-width-xlarge-1-4 {
    width: 25%;
  }
  .uk-width-xlarge-3-4 {
    width: 75%;
  }
  .uk-width-xlarge-1-5,
  .uk-width-xlarge-2-10 {
    width: 20%;
  }
  .uk-width-xlarge-2-5,
  .uk-width-xlarge-4-10 {
    width: 40%;
  }
  .uk-width-xlarge-3-5,
  .uk-width-xlarge-6-10 {
    width: 60%;
  }
  .uk-width-xlarge-4-5,
  .uk-width-xlarge-8-10 {
    width: 80%;
  }
  .uk-width-xlarge-1-6 {
    width: 16.666%;
  }
  .uk-width-xlarge-5-6 {
    width: 83.333%;
  }
  .uk-width-xlarge-1-10 {
    width: 10%;
  }
  .uk-width-xlarge-3-10 {
    width: 30%;
  }
  .uk-width-xlarge-7-10 {
    width: 70%;
  }
  .uk-width-xlarge-9-10 {
    width: 90%;
  }
}
@media (min-width: 768px) {
  [class*='uk-push-'],
  [class*='uk-pull-'] {
    position: relative;
  }
  .uk-push-1-2,
  .uk-push-2-4,
  .uk-push-3-6,
  .uk-push-5-10 {
    left: 50%;
  }
  .uk-push-1-3,
  .uk-push-2-6 {
    left: 33.333%;
  }
  .uk-push-2-3,
  .uk-push-4-6 {
    left: 66.666%;
  }
  .uk-push-1-4 {
    left: 25%;
  }
  .uk-push-3-4 {
    left: 75%;
  }
  .uk-push-1-5,
  .uk-push-2-10 {
    left: 20%;
  }
  .uk-push-2-5,
  .uk-push-4-10 {
    left: 40%;
  }
  .uk-push-3-5,
  .uk-push-6-10 {
    left: 60%;
  }
  .uk-push-4-5,
  .uk-push-8-10 {
    left: 80%;
  }
  .uk-push-1-6 {
    left: 16.666%;
  }
  .uk-push-5-6 {
    left: 83.333%;
  }
  .uk-push-1-10 {
    left: 10%;
  }
  .uk-push-3-10 {
    left: 30%;
  }
  .uk-push-7-10 {
    left: 70%;
  }
  .uk-push-9-10 {
    left: 90%;
  }
  .uk-pull-1-2,
  .uk-pull-2-4,
  .uk-pull-3-6,
  .uk-pull-5-10 {
    left: -50%;
  }
  .uk-pull-1-3,
  .uk-pull-2-6 {
    left: -33.333%;
  }
  .uk-pull-2-3,
  .uk-pull-4-6 {
    left: -66.666%;
  }
  .uk-pull-1-4 {
    left: -25%;
  }
  .uk-pull-3-4 {
    left: -75%;
  }
  .uk-pull-1-5,
  .uk-pull-2-10 {
    left: -20%;
  }
  .uk-pull-2-5,
  .uk-pull-4-10 {
    left: -40%;
  }
  .uk-pull-3-5,
  .uk-pull-6-10 {
    left: -60%;
  }
  .uk-pull-4-5,
  .uk-pull-8-10 {
    left: -80%;
  }
  .uk-pull-1-6 {
    left: -16.666%;
  }
  .uk-pull-5-6 {
    left: -83.333%;
  }
  .uk-pull-1-10 {
    left: -10%;
  }
  .uk-pull-3-10 {
    left: -30%;
  }
  .uk-pull-7-10 {
    left: -70%;
  }
  .uk-pull-9-10 {
    left: -90%;
  }
}
.uk-panel {
  display: block;
  position: relative;
}
.uk-panel,
.uk-panel:hover {
  text-decoration: none;
}
.uk-panel:before,
.uk-panel:after {
  content: "";
  display: table;
}
.uk-panel:after {
  clear: both;
}
.uk-panel > :not(.uk-panel-title):last-child {
  margin-bottom: 0;
}
.uk-panel-title {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  text-transform: none;
  color: #c8c8c8;
}
.uk-panel-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.uk-panel-teaser {
  margin-bottom: 15px;
}
.uk-panel-body {
  padding: 15px;
}
.uk-panel-box {
  padding: 15px;
  background: #1c1e22;
  color: #c8c8c8;
}
.uk-panel-box-hover:hover {
  color: #c8c8c8;
}
.uk-panel-box .uk-panel-title {
  color: #c8c8c8;
}
.uk-panel-box .uk-panel-badge {
  top: 10px;
  right: 10px;
}
.uk-panel-box > .uk-panel-teaser {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}
.uk-panel-box > .uk-nav-side {
  margin: 0 -15px;
}
.uk-panel-box-primary {
  background-color: #2e3338;
  color: #2d7091;
}
.uk-panel-box-primary-hover:hover {
  color: #2d7091;
}
.uk-panel-box-primary .uk-panel-title {
  color: #2d7091;
}
.uk-panel-box-secondary {
  background-color: #2e3338;
  color: #c8c8c8;
}
.uk-panel-box-secondary-hover:hover {
  color: #c8c8c8;
}
.uk-panel-box-secondary .uk-panel-title {
  color: #c8c8c8;
}
.uk-panel-hover {
  padding: 15px;
  color: #c8c8c8;
}
.uk-panel-hover:hover {
  background: #7a8288;
  color: #c8c8c8;
}
.uk-panel-hover .uk-panel-badge {
  top: 10px;
  right: 10px;
}
.uk-panel-hover > .uk-panel-teaser {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}
.uk-panel-header .uk-panel-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #141619;
  color: #c8c8c8;
}
.uk-panel-space {
  padding: 30px;
}
.uk-panel-space .uk-panel-badge {
  top: 30px;
  right: 30px;
}
.uk-panel + .uk-panel-divider {
  margin-top: 50px !important;
}
.uk-panel + .uk-panel-divider:before {
  content: "";
  display: block;
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  border-top: 1px solid #141619;
}
@media (min-width: 1220px) {
  .uk-panel + .uk-panel-divider {
    margin-top: 70px !important;
  }
  .uk-panel + .uk-panel-divider:before {
    top: -35px;
  }
}
.uk-block {
  position: relative;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .uk-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.uk-block:before,
.uk-block:after {
  content: "";
  display: table;
}
.uk-block:after {
  clear: both;
}
.uk-block > :last-child {
  margin-bottom: 0;
}
.uk-block-large {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .uk-block-large {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (min-width: 960px) {
  .uk-block-large {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.uk-block-default {
  background: #272b30;
}
.uk-block-muted {
  background: #f9f9f9;
}
.uk-block-primary {
  background: #5bc0de;
}
.uk-block-secondary {
  background: #222222;
}
.uk-block-default + .uk-block-default,
.uk-block-muted + .uk-block-muted,
.uk-block-primary + .uk-block-primary,
.uk-block-secondary + .uk-block-secondary {
  padding-top: 0;
}
.uk-article:before,
.uk-article:after {
  content: "";
  display: table;
}
.uk-article:after {
  clear: both;
}
.uk-article > :last-child {
  margin-bottom: 0;
}
.uk-article + .uk-article {
  margin-top: 25px;
}
.uk-article-title {
  font-size: 36px;
  line-height: 42px;
  font-weight: normal;
  text-transform: none;
}
.uk-article-title a {
  color: inherit;
  text-decoration: none;
}
.uk-article-meta {
  font-size: 12px;
  line-height: 18px;
  color: #7a8288;
}
.uk-article-lead {
  color: #c8c8c8;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
.uk-article-divider {
  margin-bottom: 25px;
  border-color: #141619;
}
* + .uk-article-divider {
  margin-top: 25px;
}
.uk-comment-header {
  margin-bottom: 15px;
}
.uk-comment-header:before,
.uk-comment-header:after {
  content: "";
  display: table;
}
.uk-comment-header:after {
  clear: both;
}
.uk-comment-avatar {
  margin-right: 15px;
  float: left;
}
.uk-comment-title {
  margin: 5px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}
.uk-comment-meta {
  margin: 2px 0 0 0;
  font-size: 11px;
  line-height: 16px;
  color: #7a8288;
}
.uk-comment-body > :last-child {
  margin-bottom: 0;
}
.uk-comment-list {
  padding: 0;
  list-style: none;
}
.uk-comment-list .uk-comment + ul {
  margin: 15px 0 0 0;
  list-style: none;
}
.uk-comment-list > li:nth-child(n+2),
.uk-comment-list .uk-comment + ul > li:nth-child(n+2) {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .uk-comment-list .uk-comment + ul {
    padding-left: 100px;
  }
}
.uk-cover-background {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.uk-cover {
  overflow: hidden;
}
.uk-cover-object {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
[data-uk-cover] {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.uk-nav li > a {
  display: block;
  text-decoration: none;
}
.uk-nav > li > a {
  padding: 5px 15px;
}
.uk-nav ul {
  padding-left: 15px;
}
.uk-nav ul a {
  padding: 2px 0;
}
.uk-nav li > a > div {
  font-size: 12px;
  line-height: 18px;
}
.uk-nav-header {
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.uk-nav-header:not(:first-child) {
  margin-top: 15px;
}
.uk-nav-divider {
  margin: 9px 15px;
}
ul.uk-nav-sub {
  padding: 5px 0 5px 15px;
}
.uk-nav-parent-icon > .uk-parent > a:after {
  content: "\f104";
  width: 20px;
  margin-right: -10px;
  float: right;
  font-family: FontAwesome;
  text-align: center;
}
.uk-nav-parent-icon > .uk-parent.uk-open > a:after {
  content: "\f107";
}
.uk-nav-side > li > a {
  color: #c8c8c8;
}
.uk-nav-side > li > a:hover,
.uk-nav-side > li > a:focus {
  background: rgba(0, 0, 0, 0.05);
  color: #c8c8c8;
  outline: none;
}
.uk-nav-side > li.uk-active > a {
  background: #7a8288;
  color: #ffffff;
}
.uk-nav-side .uk-nav-header {
  color: #c8c8c8;
}
.uk-nav-side .uk-nav-divider {
  border-top: 1px solid #141619;
}
.uk-nav-side ul a {
  color: #ffffff;
}
.uk-nav-side ul a:hover {
  color: #ffffff;
}
.uk-nav-dropdown > li > a {
  color: #c8c8c8;
}
.uk-nav-dropdown > li > a:hover,
.uk-nav-dropdown > li > a:focus {
  background: #7a8288;
  color: #ffffff;
  outline: none;
}
.uk-nav-dropdown .uk-nav-header {
  color: #7a8288;
}
.uk-nav-dropdown .uk-nav-divider {
  border-top: 1px solid #141619;
}
.uk-nav-dropdown ul a {
  color: #ffffff;
}
.uk-nav-dropdown ul a:hover {
  color: #ffffff;
}
.uk-nav-navbar > li > a {
  color: #c8c8c8;
}
.uk-nav-navbar > li > a:hover,
.uk-nav-navbar > li > a:focus {
  background: #7a8288;
  color: #ffffff;
  outline: none;
}
.uk-nav-navbar .uk-nav-header {
  color: #7a8288;
}
.uk-nav-navbar .uk-nav-divider {
  border-top: 1px solid #141619;
}
.uk-nav-navbar ul a {
  color: #ffffff;
}
.uk-nav-navbar ul a:hover {
  color: #ffffff;
}
.uk-nav-offcanvas > li > a {
  color: #cccccc;
  padding: 10px 15px;
}
.uk-nav-offcanvas > .uk-open > a,
html:not(.uk-touch) .uk-nav-offcanvas > li > a:hover,
html:not(.uk-touch) .uk-nav-offcanvas > li > a:focus {
  background: #404040;
  color: #ffffff;
  outline: none;
}
html .uk-nav.uk-nav-offcanvas > li.uk-active > a {
  background: #1a1a1a;
  color: #ffffff;
}
.uk-nav-offcanvas .uk-nav-header {
  color: #777777;
}
.uk-nav-offcanvas .uk-nav-divider {
  border-top: 1px solid #1a1a1a;
}
.uk-nav-offcanvas ul a {
  color: #cccccc;
}
html:not(.uk-touch) .uk-nav-offcanvas ul a:hover {
  color: #ffffff;
}
.uk-navbar {
  background: #3a3f44;
  color: #c8c8c8;
}
.uk-navbar:before,
.uk-navbar:after {
  content: "";
  display: table;
}
.uk-navbar:after {
  clear: both;
}
.uk-navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}
.uk-navbar-nav > li {
  float: left;
  position: relative;
}
.uk-navbar-nav > li > a {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  color: #c8c8c8;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
.uk-navbar-nav > li > a[href='#'] {
  cursor: text;
}
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li.uk-open > a {
  background-color: #272b2e;
  color: #c8c8c8;
  outline: none;
}
.uk-navbar-nav > li > a:active {
  background-color: #272b2e;
  color: #c8c8c8;
}
.uk-navbar-nav > li.uk-active > a {
  background-color: #272b2e;
  color: #c8c8c8;
}
.uk-navbar-nav .uk-navbar-nav-subtitle {
  line-height: 28px;
}
.uk-navbar-nav-subtitle > div {
  margin-top: -6px;
  font-size: 10px;
  line-height: 12px;
}
.uk-navbar-content,
.uk-navbar-brand,
.uk-navbar-toggle {
  box-sizing: border-box;
  display: block;
  height: 40px;
  padding: 0 15px;
  float: left;
}
.uk-navbar-content:before,
.uk-navbar-brand:before,
.uk-navbar-toggle:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.uk-navbar-content + .uk-navbar-content:not(.uk-navbar-center) {
  padding-left: 0;
}
.uk-navbar-content > a:not([class]) {
  color: #ffffff;
}
.uk-navbar-content > a:not([class]):hover {
  color: #ffffff;
}
.uk-navbar-brand {
  font-size: 18px;
  color: #c8c8c8;
  text-decoration: none;
}
.uk-navbar-brand:hover,
.uk-navbar-brand:focus {
  color: #c8c8c8;
  text-decoration: none;
  outline: none;
}
.uk-navbar-toggle {
  font-size: 18px;
  color: #c8c8c8;
  text-decoration: none;
}
.uk-navbar-toggle:hover,
.uk-navbar-toggle:focus {
  color: #c8c8c8;
  text-decoration: none;
  outline: none;
}
.uk-navbar-toggle:after {
  content: "\f0c9";
  font-family: FontAwesome;
  vertical-align: middle;
}
.uk-navbar-toggle-alt:after {
  content: "\f002";
}
.uk-navbar-center {
  float: none;
  text-align: center;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.uk-navbar-flip {
  float: right;
}
.uk-subnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;
  padding: 0;
  list-style: none;
}
.uk-subnav > * {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  padding-left: 10px;
  margin-top: 10px;
  position: relative;
}
.uk-subnav:before,
.uk-subnav:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-subnav:after {
  clear: both;
}
.uk-subnav > * {
  float: left;
}
.uk-subnav > * > * {
  display: inline-block;
  color: #c8c8c8;
}
.uk-subnav > * > :hover,
.uk-subnav > * > :focus {
  color: #ffffff;
  text-decoration: none;
}
.uk-subnav > .uk-active > * {
  color: #ffffff;
}
.uk-subnav-line > :before {
  content: "";
  display: inline-block;
  height: 10px;
  vertical-align: middle;
}
.uk-subnav-line > :nth-child(n+2):before {
  margin-right: 10px;
  border-left: 1px solid #141619;
}
.uk-subnav-pill > * > * {
  padding: 3px 9px;
}
.uk-subnav-pill > * > :hover,
.uk-subnav-pill > * > :focus {
  background: #3e444c;
  color: #c8c8c8;
  text-decoration: none;
  outline: none;
}
.uk-subnav-pill > .uk-active > * {
  background: #141618;
  color: #ffffff;
}
.uk-subnav > .uk-disabled > * {
  background: none;
  color: #7a8288;
  text-decoration: none;
  cursor: text;
}
.uk-breadcrumb {
  padding: 0;
  list-style: none;
  font-size: 0.001px;
}
.uk-breadcrumb > li {
  font-size: 1rem;
  vertical-align: top;
}
.uk-breadcrumb > li,
.uk-breadcrumb > li > a,
.uk-breadcrumb > li > span {
  display: inline-block;
}
.uk-breadcrumb > li:nth-child(n+2):before {
  content: "/";
  display: inline-block;
  margin: 0 8px;
}
.uk-breadcrumb > li:not(.uk-active) > span {
  color: #7a8288;
}
.uk-pagination {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 0.001px;
}
.uk-pagination:before,
.uk-pagination:after {
  content: "";
  display: table;
}
.uk-pagination:after {
  clear: both;
}
.uk-pagination > li {
  display: inline-block;
  font-size: 1rem;
  vertical-align: top;
}
.uk-pagination > li:nth-child(n+2) {
  margin-left: 5px;
}
.uk-pagination > li > a,
.uk-pagination > li > span {
  display: inline-block;
  min-width: 16px;
  padding: 3px 5px;
  line-height: 20px;
  text-decoration: none;
  box-sizing: content-box;
  text-align: center;
}
.uk-pagination > li > a {
  background: #3a3f44;
  color: #c8c8c8;
}
.uk-pagination > li > a:hover,
.uk-pagination > li > a:focus {
  background-color: #232628;
  color: #c8c8c8;
  outline: none;
}
.uk-pagination > li > a:active {
  background-color: #232628;
  color: #c8c8c8;
}
.uk-pagination > .uk-active > span {
  background: #7a8288;
  color: #ffffff;
}
.uk-pagination > .uk-disabled > span {
  background-color: #3a3f44;
  color: #4c5156;
}
.uk-pagination-previous {
  float: left;
}
.uk-pagination-next {
  float: right;
}
.uk-pagination-left {
  text-align: left;
}
.uk-pagination-right {
  text-align: right;
}
.uk-tab {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #141619;
}
.uk-tab:before,
.uk-tab:after {
  content: "";
  display: table;
}
.uk-tab:after {
  clear: both;
}
.uk-tab > li {
  margin-bottom: -1px;
  float: left;
  position: relative;
}
.uk-tab > li > a {
  display: block;
  padding: 8px 12px 8px 12px;
  border: 1px solid transparent;
  border-bottom-width: 0;
  color: #ffffff;
  text-decoration: none;
}
.uk-tab > li:nth-child(n+2) > a {
  margin-left: 5px;
}
.uk-tab > li > a:hover,
.uk-tab > li > a:focus,
.uk-tab > li.uk-open > a {
  border-color: #1c1e22;
  background: #3e444c;
  color: #ffffff;
  outline: none;
}
.uk-tab > li:not(.uk-active) > a:hover,
.uk-tab > li:not(.uk-active) > a:focus,
.uk-tab > li.uk-open:not(.uk-active) > a {
  margin-bottom: 1px;
  padding-bottom: 7px;
}
.uk-tab > li.uk-active > a {
  border-color: #141619;
  border-bottom-color: transparent;
  background: #272b30;
  color: #c8c8c8;
}
.uk-tab > li.uk-disabled > a {
  color: #7a8288;
  cursor: text;
}
.uk-tab > li.uk-disabled > a:hover,
.uk-tab > li.uk-disabled > a:focus,
.uk-tab > li.uk-disabled.uk-active > a {
  background: none;
  border-color: transparent;
}
.uk-tab-flip > li {
  float: right;
}
.uk-tab-flip > li:nth-child(n+2) > a {
  margin-left: 0;
  margin-right: 5px;
}
.uk-tab > li.uk-tab-responsive > a {
  margin-left: 0;
  margin-right: 0;
}
.uk-tab-responsive > a:before {
  content: "\f0c9\00a0";
  font-family: FontAwesome;
}
.uk-tab-center {
  border-bottom: 1px solid #141619;
}
.uk-tab-center-bottom {
  border-bottom: none;
  border-top: 1px solid #141619;
}
.uk-tab-center:before,
.uk-tab-center:after {
  content: "";
  display: table;
}
.uk-tab-center:after {
  clear: both;
}
.uk-tab-center .uk-tab {
  position: relative;
  right: 50%;
  border: none;
  float: right;
}
.uk-tab-center .uk-tab > li {
  position: relative;
  right: -50%;
}
.uk-tab-center .uk-tab > li > a {
  text-align: center;
}
.uk-tab-bottom {
  border-top: 1px solid #141619;
  border-bottom: none;
}
.uk-tab-bottom > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.uk-tab-bottom > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-top-width: 0;
}
.uk-tab-bottom > li:not(.uk-active) > a:hover,
.uk-tab-bottom > li:not(.uk-active) > a:focus,
.uk-tab-bottom > li.uk-open:not(.uk-active) > a {
  margin-bottom: 0;
  margin-top: 1px;
  padding-bottom: 8px;
  padding-top: 7px;
}
.uk-tab-bottom > li.uk-active > a {
  border-top-color: transparent;
  border-bottom-color: #141619;
}
.uk-tab-grid {
  margin-left: -5px;
  border-bottom: none;
  position: relative;
  z-index: 0;
}
.uk-tab-grid:before {
  display: block;
  position: absolute;
  left: 5px;
  right: 0;
  bottom: -1px;
  border-top: 1px solid #141619;
  z-index: -1;
}
.uk-tab-grid > li:first-child > a {
  margin-left: 5px;
}
.uk-tab-grid > li > a {
  text-align: center;
}
.uk-tab-grid.uk-tab-bottom {
  border-top: none;
}
.uk-tab-grid.uk-tab-bottom:before {
  top: -1px;
  bottom: auto;
}
@media (min-width: 768px) {
  .uk-tab-left,
  .uk-tab-right {
    border-bottom: none;
  }
  .uk-tab-left > li,
  .uk-tab-right > li {
    margin-bottom: 0;
    float: none;
  }
  .uk-tab-left > li > a,
  .uk-tab-right > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .uk-tab-left > li:nth-child(n+2) > a,
  .uk-tab-right > li:nth-child(n+2) > a {
    margin-left: 0;
    margin-top: 5px;
  }
  .uk-tab-left > li.uk-active > a,
  .uk-tab-right > li.uk-active > a {
    border-color: #141619;
  }
  .uk-tab-left {
    border-right: 1px solid #141619;
  }
  .uk-tab-left > li {
    margin-right: -1px;
  }
  .uk-tab-left > li > a {
    border-bottom-width: 1px;
    border-right-width: 0;
  }
  .uk-tab-left > li:not(.uk-active) > a:hover,
  .uk-tab-left > li:not(.uk-active) > a:focus {
    margin-bottom: 0;
    margin-right: 1px;
    padding-bottom: 8px;
    padding-right: 11px;
  }
  .uk-tab-left > li.uk-active > a {
    border-right-color: transparent;
  }
  .uk-tab-right {
    border-left: 1px solid #141619;
  }
  .uk-tab-right > li {
    margin-left: -1px;
  }
  .uk-tab-right > li > a {
    border-bottom-width: 1px;
    border-left-width: 0;
  }
  .uk-tab-right > li:not(.uk-active) > a:hover,
  .uk-tab-right > li:not(.uk-active) > a:focus {
    margin-bottom: 0;
    margin-left: 1px;
    padding-bottom: 8px;
    padding-left: 11px;
  }
  .uk-tab-right > li.uk-active > a {
    border-left-color: transparent;
  }
}
.uk-thumbnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;
  padding: 0;
  list-style: none;
}
.uk-thumbnav > * {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  padding-left: 10px;
  margin-top: 10px;
}
.uk-thumbnav:before,
.uk-thumbnav:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-thumbnav:after {
  clear: both;
}
.uk-thumbnav > * {
  float: left;
}
.uk-thumbnav > * > * {
  display: block;
  background: #ffffff;
}
.uk-thumbnav > * > * > img {
  opacity: 0.7;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.uk-thumbnav > * > :hover > img,
.uk-thumbnav > * > :focus > img {
  opacity: 1;
}
.uk-thumbnav > .uk-active > * > img {
  opacity: 1;
}
.uk-list {
  padding: 0;
  list-style: none;
}
.uk-list > li:before,
.uk-list > li:after {
  content: "";
  display: table;
}
.uk-list > li:after {
  clear: both;
}
.uk-list > li > :last-child {
  margin-bottom: 0;
}
.uk-list ul {
  margin: 0;
  padding-left: 20px;
  list-style: none;
}
.uk-list-line > li:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #141619;
}
.uk-list-striped > li {
  padding: 5px 5px;
}
.uk-list-striped > li:nth-of-type(odd) {
  background: #353a41;
}
.uk-list-space > li:nth-child(n+2) {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .uk-description-list-horizontal {
    overflow: hidden;
  }
  .uk-description-list-horizontal > dt {
    width: 160px;
    float: left;
    clear: both;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .uk-description-list-horizontal > dd {
    margin-left: 180px;
  }
}
.uk-description-list-line > dt {
  font-weight: normal;
}
.uk-description-list-line > dt:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #141619;
}
.uk-description-list-line > dd {
  color: #7a8288;
}
.uk-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 15px;
}
* + .uk-table {
  margin-top: 15px;
}
.uk-table th,
.uk-table td {
  padding: 8px 8px;
}
.uk-table th {
  text-align: left;
}
.uk-table td {
  vertical-align: top;
}
.uk-table thead th {
  vertical-align: bottom;
}
.uk-table caption,
.uk-table tfoot {
  font-size: 12px;
  font-style: italic;
}
.uk-table caption {
  text-align: left;
  color: #7a8288;
}
.uk-table tbody tr.uk-active {
  background: #49515a;
}
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #353a41;
}
.uk-table-condensed td {
  padding: 4px 8px;
}
.uk-table-hover tbody tr:hover {
  background: #49515a;
}
.uk-form input,
.uk-form select,
.uk-form textarea {
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  color: inherit;
}
.uk-form select {
  text-transform: none;
}
.uk-form optgroup {
  font: inherit;
  font-weight: bold;
}
.uk-form input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.uk-form input[type="checkbox"],
.uk-form input[type="radio"] {
  padding: 0;
}
.uk-form input[type="checkbox"]:not(:disabled),
.uk-form input[type="radio"]:not(:disabled) {
  cursor: pointer;
}
.uk-form textarea,
.uk-form input:not([type]),
.uk-form input[type="text"],
.uk-form input[type="password"],
.uk-form input[type="email"],
.uk-form input[type="url"],
.uk-form input[type="search"],
.uk-form input[type="tel"],
.uk-form input[type="number"],
.uk-form input[type="datetime"] {
  -webkit-appearance: none;
}
.uk-form input[type="search"]::-webkit-search-cancel-button,
.uk-form input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.uk-form input[type="number"]::-webkit-inner-spin-button,
.uk-form input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
.uk-form fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.uk-form textarea {
  overflow: auto;
  vertical-align: top;
}
.uk-form ::-moz-placeholder {
  opacity: 1;
}
.uk-form :invalid {
  box-shadow: none;
}
.uk-form input:not([type="radio"]):not([type="checkbox"]),
.uk-form select {
  vertical-align: middle;
}
.uk-form > :last-child {
  margin-bottom: 0;
}
.uk-form select,
.uk-form textarea,
.uk-form input:not([type]),
.uk-form input[type="text"],
.uk-form input[type="password"],
.uk-form input[type="datetime"],
.uk-form input[type="datetime-local"],
.uk-form input[type="date"],
.uk-form input[type="month"],
.uk-form input[type="time"],
.uk-form input[type="week"],
.uk-form input[type="number"],
.uk-form input[type="email"],
.uk-form input[type="url"],
.uk-form input[type="search"],
.uk-form input[type="tel"],
.uk-form input[type="color"] {
  height: 30px;
  max-width: 100%;
  padding: 4px 6px;
  border: 1px solid #141619;
  background: #1c1e22;
  color: #c8c8c8;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
}
.uk-form select:focus,
.uk-form textarea:focus,
.uk-form input:not([type]):focus,
.uk-form input[type="text"]:focus,
.uk-form input[type="password"]:focus,
.uk-form input[type="datetime"]:focus,
.uk-form input[type="datetime-local"]:focus,
.uk-form input[type="date"]:focus,
.uk-form input[type="month"]:focus,
.uk-form input[type="time"]:focus,
.uk-form input[type="week"]:focus,
.uk-form input[type="number"]:focus,
.uk-form input[type="email"]:focus,
.uk-form input[type="url"]:focus,
.uk-form input[type="search"]:focus,
.uk-form input[type="tel"]:focus,
.uk-form input[type="color"]:focus {
  border-color: #66afe9;
  outline: 0;
  background: #272b30;
  color: #c8c8c8;
}
.uk-form select:disabled,
.uk-form textarea:disabled,
.uk-form input:not([type]):disabled,
.uk-form input[type="text"]:disabled,
.uk-form input[type="password"]:disabled,
.uk-form input[type="datetime"]:disabled,
.uk-form input[type="datetime-local"]:disabled,
.uk-form input[type="date"]:disabled,
.uk-form input[type="month"]:disabled,
.uk-form input[type="time"]:disabled,
.uk-form input[type="week"]:disabled,
.uk-form input[type="number"]:disabled,
.uk-form input[type="email"]:disabled,
.uk-form input[type="url"]:disabled,
.uk-form input[type="search"]:disabled,
.uk-form input[type="tel"]:disabled,
.uk-form input[type="color"]:disabled {
  border-color: #141619;
  background-color: #999999;
  color: #272b30;
}
.uk-form :-ms-input-placeholder {
  color: #7a8288 !important;
}
.uk-form ::-moz-placeholder {
  color: #7a8288;
}
.uk-form ::-webkit-input-placeholder {
  color: #7a8288;
}
.uk-form :disabled:-ms-input-placeholder {
  color: #272b30 !important;
}
.uk-form :disabled::-moz-placeholder {
  color: #272b30;
}
.uk-form :disabled::-webkit-input-placeholder {
  color: #272b30;
}
.uk-form legend {
  width: 100%;
  border: 0;
  padding: 0;
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 30px;
}
.uk-form legend:after {
  content: "";
  display: block;
  border-bottom: 1px solid #141619;
  width: 100%;
}
select.uk-form-small,
textarea.uk-form-small,
input[type].uk-form-small,
input:not([type]).uk-form-small {
  height: 25px;
  padding: 3px 3px;
  font-size: 12px;
}
select.uk-form-large,
textarea.uk-form-large,
input[type].uk-form-large,
input:not([type]).uk-form-large {
  height: 40px;
  padding: 8px 6px;
  font-size: 16px;
}
.uk-form textarea,
.uk-form select[multiple],
.uk-form select[size] {
  height: auto;
}
.uk-form-danger {
  border-color: #ee5f5b !important;
  background: #fff7f8 !important;
  color: #d85030 !important;
}
.uk-form-success {
  border-color: #62c462 !important;
  background: #fafff2 !important;
  color: #659f13 !important;
}
.uk-form-blank {
  border-color: transparent !important;
  border-style: dashed !important;
  background: none !important;
}
.uk-form-blank:focus {
  border-color: #141619 !important;
}
input.uk-form-width-mini {
  width: 40px;
}
select.uk-form-width-mini {
  width: 65px;
}
.uk-form-width-small {
  width: 130px;
}
.uk-form-width-medium {
  width: 200px;
}
.uk-form-width-large {
  width: 500px;
}
.uk-form-row:before,
.uk-form-row:after {
  content: "";
  display: table;
}
.uk-form-row:after {
  clear: both;
}
.uk-form-row + .uk-form-row {
  margin-top: 15px;
}
.uk-form-help-inline {
  display: inline-block;
  margin: 0 0 0 10px;
}
.uk-form-help-block {
  margin: 5px 0 0 0;
}
.uk-form-controls > :first-child {
  margin-top: 0;
}
.uk-form-controls > :last-child {
  margin-bottom: 0;
}
.uk-form-controls-condensed {
  margin: 5px 0;
}
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
@media (max-width: 959px) {
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
}
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 5px;
    float: left;
  }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 5px;
  }
}
.uk-form-icon {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.uk-form-icon > [class*='uk-icon-'] {
  position: absolute;
  top: 50%;
  width: 30px;
  margin-top: -7px;
  font-size: 14px;
  color: #7a8288;
  text-align: center;
  pointer-events: none;
}
.uk-form-icon:not(.uk-form-icon-flip) > input {
  padding-left: 30px !important;
}
.uk-form-icon-flip > [class*='uk-icon-'] {
  right: 0;
}
.uk-form-icon-flip > input {
  padding-right: 30px !important;
}
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.uk-button {
  -webkit-appearance: none;
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: #ffffff;
  text-transform: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 12px;
  background: #3a3f44;
  vertical-align: middle;
  line-height: 30px;
  min-height: 30px;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
}
.uk-button:not(:disabled) {
  cursor: pointer;
}
.uk-button:hover,
.uk-button:focus {
  background-color: #232628;
  color: #c8c8c8;
  outline: none;
  text-decoration: none;
}
.uk-button:active,
.uk-button.uk-active {
  background-color: #232628;
  color: #c8c8c8;
}
.uk-button-primary {
  background-color: #7a8288;
  color: #ffffff;
}
.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: #62686d;
  color: #ffffff;
}
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #62686d;
  color: #ffffff;
}
.uk-button-success {
  background-color: #62c462;
  color: #ffffff;
}
.uk-button-success:hover,
.uk-button-success:focus {
  background-color: #42b142;
  color: #ffffff;
}
.uk-button-success:active,
.uk-button-success.uk-active {
  background-color: #42b142;
  color: #ffffff;
}
.uk-button-danger {
  background-color: #ee5f5b;
  color: #ffffff;
}
.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: #e9322d;
  color: #ffffff;
}
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #e9322d;
  color: #ffffff;
}
.uk-button:disabled {
  background-color: #aeabab;
  color: #7a8288;
}
.uk-button-link,
.uk-button-link:hover,
.uk-button-link:focus,
.uk-button-link:active,
.uk-button-link.uk-active,
.uk-button-link:disabled {
  border-color: transparent;
  background: none;
}
.uk-button-link {
  color: #ffffff;
}
.uk-button-link:hover,
.uk-button-link:focus,
.uk-button-link:active,
.uk-button-link.uk-active {
  color: #ffffff;
  text-decoration: underline;
}
.uk-button-link:disabled {
  color: #7a8288;
}
.uk-button-link:focus {
  outline: 1px dotted;
}
.uk-button-mini {
  min-height: 20px;
  padding: 0 6px;
  line-height: 20px;
  font-size: 11px;
}
.uk-button-small {
  min-height: 25px;
  padding: 0 10px;
  line-height: 25px;
  font-size: 12px;
}
.uk-button-large {
  min-height: 40px;
  padding: 0 15px;
  line-height: 40px;
  font-size: 16px;
}
.uk-button-group {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 0.001px;
  white-space: nowrap;
}
.uk-button-group > * {
  display: inline-block;
}
.uk-button-group .uk-button {
  vertical-align: top;
}
.uk-button-dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.woff2") format('woff2'), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
[class*='uk-icon-'] {
  font-family: FontAwesome;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class*='uk-icon-'],
[class*='uk-icon-']:hover,
[class*='uk-icon-']:focus {
  text-decoration: none;
}
.uk-icon-small {
  font-size: 150%;
  vertical-align: -10%;
}
.uk-icon-medium {
  font-size: 200%;
  vertical-align: -16%;
}
.uk-icon-large {
  font-size: 250%;
  vertical-align: -22%;
}
.uk-icon-justify {
  width: 1em;
  text-align: center;
}
.uk-icon-spin {
  display: inline-block;
  -webkit-animation: uk-rotate 2s infinite linear;
  animation: uk-rotate 2s infinite linear;
}
.uk-icon-hover {
  color: #7a8288;
}
.uk-icon-hover:hover {
  color: #c8c8c8;
}
.uk-icon-button {
  box-sizing: border-box;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #3a3f44;
  line-height: 35px;
  color: #c8c8c8;
  font-size: 18px;
  text-align: center;
}
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #232628;
  color: #c8c8c8;
  outline: none;
}
.uk-icon-button:active {
  background-color: #232628;
  color: #c8c8c8;
}
.uk-icon-glass:before {
  content: "\f000";
}
.uk-icon-music:before {
  content: "\f001";
}
.uk-icon-search:before {
  content: "\f002";
}
.uk-icon-envelope-o:before {
  content: "\f003";
}
.uk-icon-heart:before {
  content: "\f004";
}
.uk-icon-star:before {
  content: "\f005";
}
.uk-icon-star-o:before {
  content: "\f006";
}
.uk-icon-user:before {
  content: "\f007";
}
.uk-icon-film:before {
  content: "\f008";
}
.uk-icon-th-large:before {
  content: "\f009";
}
.uk-icon-th:before {
  content: "\f00a";
}
.uk-icon-th-list:before {
  content: "\f00b";
}
.uk-icon-check:before {
  content: "\f00c";
}
.uk-icon-remove:before,
.uk-icon-close:before,
.uk-icon-times:before {
  content: "\f00d";
}
.uk-icon-search-plus:before {
  content: "\f00e";
}
.uk-icon-search-minus:before {
  content: "\f010";
}
.uk-icon-power-off:before {
  content: "\f011";
}
.uk-icon-signal:before {
  content: "\f012";
}
.uk-icon-gear:before,
.uk-icon-cog:before {
  content: "\f013";
}
.uk-icon-trash-o:before {
  content: "\f014";
}
.uk-icon-home:before {
  content: "\f015";
}
.uk-icon-file-o:before {
  content: "\f016";
}
.uk-icon-clock-o:before {
  content: "\f017";
}
.uk-icon-road:before {
  content: "\f018";
}
.uk-icon-download:before {
  content: "\f019";
}
.uk-icon-arrow-circle-o-down:before {
  content: "\f01a";
}
.uk-icon-arrow-circle-o-up:before {
  content: "\f01b";
}
.uk-icon-inbox:before {
  content: "\f01c";
}
.uk-icon-play-circle-o:before {
  content: "\f01d";
}
.uk-icon-rotate-right:before,
.uk-icon-repeat:before {
  content: "\f01e";
}
.uk-icon-refresh:before {
  content: "\f021";
}
.uk-icon-list-alt:before {
  content: "\f022";
}
.uk-icon-lock:before {
  content: "\f023";
}
.uk-icon-flag:before {
  content: "\f024";
}
.uk-icon-headphones:before {
  content: "\f025";
}
.uk-icon-volume-off:before {
  content: "\f026";
}
.uk-icon-volume-down:before {
  content: "\f027";
}
.uk-icon-volume-up:before {
  content: "\f028";
}
.uk-icon-qrcode:before {
  content: "\f029";
}
.uk-icon-barcode:before {
  content: "\f02a";
}
.uk-icon-tag:before {
  content: "\f02b";
}
.uk-icon-tags:before {
  content: "\f02c";
}
.uk-icon-book:before {
  content: "\f02d";
}
.uk-icon-bookmark:before {
  content: "\f02e";
}
.uk-icon-print:before {
  content: "\f02f";
}
.uk-icon-camera:before {
  content: "\f030";
}
.uk-icon-font:before {
  content: "\f031";
}
.uk-icon-bold:before {
  content: "\f032";
}
.uk-icon-italic:before {
  content: "\f033";
}
.uk-icon-text-height:before {
  content: "\f034";
}
.uk-icon-text-width:before {
  content: "\f035";
}
.uk-icon-align-left:before {
  content: "\f036";
}
.uk-icon-align-center:before {
  content: "\f037";
}
.uk-icon-align-right:before {
  content: "\f038";
}
.uk-icon-align-justify:before {
  content: "\f039";
}
.uk-icon-list:before {
  content: "\f03a";
}
.uk-icon-dedent:before,
.uk-icon-outdent:before {
  content: "\f03b";
}
.uk-icon-indent:before {
  content: "\f03c";
}
.uk-icon-video-camera:before {
  content: "\f03d";
}
.uk-icon-photo:before,
.uk-icon-image:before,
.uk-icon-picture-o:before {
  content: "\f03e";
}
.uk-icon-pencil:before {
  content: "\f040";
}
.uk-icon-map-marker:before {
  content: "\f041";
}
.uk-icon-adjust:before {
  content: "\f042";
}
.uk-icon-tint:before {
  content: "\f043";
}
.uk-icon-edit:before,
.uk-icon-pencil-square-o:before {
  content: "\f044";
}
.uk-icon-share-square-o:before {
  content: "\f045";
}
.uk-icon-check-square-o:before {
  content: "\f046";
}
.uk-icon-arrows:before {
  content: "\f047";
}
.uk-icon-step-backward:before {
  content: "\f048";
}
.uk-icon-fast-backward:before {
  content: "\f049";
}
.uk-icon-backward:before {
  content: "\f04a";
}
.uk-icon-play:before {
  content: "\f04b";
}
.uk-icon-pause:before {
  content: "\f04c";
}
.uk-icon-stop:before {
  content: "\f04d";
}
.uk-icon-forward:before {
  content: "\f04e";
}
.uk-icon-fast-forward:before {
  content: "\f050";
}
.uk-icon-step-forward:before {
  content: "\f051";
}
.uk-icon-eject:before {
  content: "\f052";
}
.uk-icon-chevron-left:before {
  content: "\f053";
}
.uk-icon-chevron-right:before {
  content: "\f054";
}
.uk-icon-plus-circle:before {
  content: "\f055";
}
.uk-icon-minus-circle:before {
  content: "\f056";
}
.uk-icon-times-circle:before {
  content: "\f057";
}
.uk-icon-check-circle:before {
  content: "\f058";
}
.uk-icon-question-circle:before {
  content: "\f059";
}
.uk-icon-info-circle:before {
  content: "\f05a";
}
.uk-icon-crosshairs:before {
  content: "\f05b";
}
.uk-icon-times-circle-o:before {
  content: "\f05c";
}
.uk-icon-check-circle-o:before {
  content: "\f05d";
}
.uk-icon-ban:before {
  content: "\f05e";
}
.uk-icon-arrow-left:before {
  content: "\f060";
}
.uk-icon-arrow-right:before {
  content: "\f061";
}
.uk-icon-arrow-up:before {
  content: "\f062";
}
.uk-icon-arrow-down:before {
  content: "\f063";
}
.uk-icon-mail-forward:before,
.uk-icon-share:before {
  content: "\f064";
}
.uk-icon-expand:before {
  content: "\f065";
}
.uk-icon-compress:before {
  content: "\f066";
}
.uk-icon-plus:before {
  content: "\f067";
}
.uk-icon-minus:before {
  content: "\f068";
}
.uk-icon-asterisk:before {
  content: "\f069";
}
.uk-icon-exclamation-circle:before {
  content: "\f06a";
}
.uk-icon-gift:before {
  content: "\f06b";
}
.uk-icon-leaf:before {
  content: "\f06c";
}
.uk-icon-fire:before {
  content: "\f06d";
}
.uk-icon-eye:before {
  content: "\f06e";
}
.uk-icon-eye-slash:before {
  content: "\f070";
}
.uk-icon-warning:before,
.uk-icon-exclamation-triangle:before {
  content: "\f071";
}
.uk-icon-plane:before {
  content: "\f072";
}
.uk-icon-calendar:before {
  content: "\f073";
}
.uk-icon-random:before {
  content: "\f074";
}
.uk-icon-comment:before {
  content: "\f075";
}
.uk-icon-magnet:before {
  content: "\f076";
}
.uk-icon-chevron-up:before {
  content: "\f077";
}
.uk-icon-chevron-down:before {
  content: "\f078";
}
.uk-icon-retweet:before {
  content: "\f079";
}
.uk-icon-shopping-cart:before {
  content: "\f07a";
}
.uk-icon-folder:before {
  content: "\f07b";
}
.uk-icon-folder-open:before {
  content: "\f07c";
}
.uk-icon-arrows-v:before {
  content: "\f07d";
}
.uk-icon-arrows-h:before {
  content: "\f07e";
}
.uk-icon-bar-chart-o:before,
.uk-icon-bar-chart:before {
  content: "\f080";
}
.uk-icon-twitter-square:before {
  content: "\f081";
}
.uk-icon-facebook-square:before {
  content: "\f082";
}
.uk-icon-camera-retro:before {
  content: "\f083";
}
.uk-icon-key:before {
  content: "\f084";
}
.uk-icon-gears:before,
.uk-icon-cogs:before {
  content: "\f085";
}
.uk-icon-comments:before {
  content: "\f086";
}
.uk-icon-thumbs-o-up:before {
  content: "\f087";
}
.uk-icon-thumbs-o-down:before {
  content: "\f088";
}
.uk-icon-star-half:before {
  content: "\f089";
}
.uk-icon-heart-o:before {
  content: "\f08a";
}
.uk-icon-sign-out:before {
  content: "\f08b";
}
.uk-icon-linkedin-square:before {
  content: "\f08c";
}
.uk-icon-thumb-tack:before {
  content: "\f08d";
}
.uk-icon-external-link:before {
  content: "\f08e";
}
.uk-icon-sign-in:before {
  content: "\f090";
}
.uk-icon-trophy:before {
  content: "\f091";
}
.uk-icon-github-square:before {
  content: "\f092";
}
.uk-icon-upload:before {
  content: "\f093";
}
.uk-icon-lemon-o:before {
  content: "\f094";
}
.uk-icon-phone:before {
  content: "\f095";
}
.uk-icon-square-o:before {
  content: "\f096";
}
.uk-icon-bookmark-o:before {
  content: "\f097";
}
.uk-icon-phone-square:before {
  content: "\f098";
}
.uk-icon-twitter:before {
  content: "\f099";
}
.uk-icon-facebook-f:before,
.uk-icon-facebook:before {
  content: "\f09a";
}
.uk-icon-github:before {
  content: "\f09b";
}
.uk-icon-unlock:before {
  content: "\f09c";
}
.uk-icon-credit-card:before {
  content: "\f09d";
}
.uk-icon-rss:before {
  content: "\f09e";
}
.uk-icon-hdd-o:before {
  content: "\f0a0";
}
.uk-icon-bullhorn:before {
  content: "\f0a1";
}
.uk-icon-bell:before {
  content: "\f0f3";
}
.uk-icon-certificate:before {
  content: "\f0a3";
}
.uk-icon-hand-o-right:before {
  content: "\f0a4";
}
.uk-icon-hand-o-left:before {
  content: "\f0a5";
}
.uk-icon-hand-o-up:before {
  content: "\f0a6";
}
.uk-icon-hand-o-down:before {
  content: "\f0a7";
}
.uk-icon-arrow-circle-left:before {
  content: "\f0a8";
}
.uk-icon-arrow-circle-right:before {
  content: "\f0a9";
}
.uk-icon-arrow-circle-up:before {
  content: "\f0aa";
}
.uk-icon-arrow-circle-down:before {
  content: "\f0ab";
}
.uk-icon-globe:before {
  content: "\f0ac";
}
.uk-icon-wrench:before {
  content: "\f0ad";
}
.uk-icon-tasks:before {
  content: "\f0ae";
}
.uk-icon-filter:before {
  content: "\f0b0";
}
.uk-icon-briefcase:before {
  content: "\f0b1";
}
.uk-icon-arrows-alt:before {
  content: "\f0b2";
}
.uk-icon-group:before,
.uk-icon-users:before {
  content: "\f0c0";
}
.uk-icon-chain:before,
.uk-icon-link:before {
  content: "\f0c1";
}
.uk-icon-cloud:before {
  content: "\f0c2";
}
.uk-icon-flask:before {
  content: "\f0c3";
}
.uk-icon-cut:before,
.uk-icon-scissors:before {
  content: "\f0c4";
}
.uk-icon-copy:before,
.uk-icon-files-o:before {
  content: "\f0c5";
}
.uk-icon-paperclip:before {
  content: "\f0c6";
}
.uk-icon-save:before,
.uk-icon-floppy-o:before {
  content: "\f0c7";
}
.uk-icon-square:before {
  content: "\f0c8";
}
.uk-icon-navicon:before,
.uk-icon-reorder:before,
.uk-icon-bars:before {
  content: "\f0c9";
}
.uk-icon-list-ul:before {
  content: "\f0ca";
}
.uk-icon-list-ol:before {
  content: "\f0cb";
}
.uk-icon-strikethrough:before {
  content: "\f0cc";
}
.uk-icon-underline:before {
  content: "\f0cd";
}
.uk-icon-table:before {
  content: "\f0ce";
}
.uk-icon-magic:before {
  content: "\f0d0";
}
.uk-icon-truck:before {
  content: "\f0d1";
}
.uk-icon-pinterest:before {
  content: "\f0d2";
}
.uk-icon-pinterest-square:before {
  content: "\f0d3";
}
.uk-icon-google-plus-square:before {
  content: "\f0d4";
}
.uk-icon-google-plus:before {
  content: "\f0d5";
}
.uk-icon-money:before {
  content: "\f0d6";
}
.uk-icon-caret-down:before {
  content: "\f0d7";
}
.uk-icon-caret-up:before {
  content: "\f0d8";
}
.uk-icon-caret-left:before {
  content: "\f0d9";
}
.uk-icon-caret-right:before {
  content: "\f0da";
}
.uk-icon-columns:before {
  content: "\f0db";
}
.uk-icon-unsorted:before,
.uk-icon-sort:before {
  content: "\f0dc";
}
.uk-icon-sort-down:before,
.uk-icon-sort-desc:before {
  content: "\f0dd";
}
.uk-icon-sort-up:before,
.uk-icon-sort-asc:before {
  content: "\f0de";
}
.uk-icon-envelope:before {
  content: "\f0e0";
}
.uk-icon-linkedin:before {
  content: "\f0e1";
}
.uk-icon-rotate-left:before,
.uk-icon-undo:before {
  content: "\f0e2";
}
.uk-icon-legal:before,
.uk-icon-gavel:before {
  content: "\f0e3";
}
.uk-icon-dashboard:before,
.uk-icon-tachometer:before {
  content: "\f0e4";
}
.uk-icon-comment-o:before {
  content: "\f0e5";
}
.uk-icon-comments-o:before {
  content: "\f0e6";
}
.uk-icon-flash:before,
.uk-icon-bolt:before {
  content: "\f0e7";
}
.uk-icon-sitemap:before {
  content: "\f0e8";
}
.uk-icon-umbrella:before {
  content: "\f0e9";
}
.uk-icon-paste:before,
.uk-icon-clipboard:before {
  content: "\f0ea";
}
.uk-icon-lightbulb-o:before {
  content: "\f0eb";
}
.uk-icon-exchange:before {
  content: "\f0ec";
}
.uk-icon-cloud-download:before {
  content: "\f0ed";
}
.uk-icon-cloud-upload:before {
  content: "\f0ee";
}
.uk-icon-user-md:before {
  content: "\f0f0";
}
.uk-icon-stethoscope:before {
  content: "\f0f1";
}
.uk-icon-suitcase:before {
  content: "\f0f2";
}
.uk-icon-bell-o:before {
  content: "\f0a2";
}
.uk-icon-coffee:before {
  content: "\f0f4";
}
.uk-icon-cutlery:before {
  content: "\f0f5";
}
.uk-icon-file-text-o:before {
  content: "\f0f6";
}
.uk-icon-building-o:before {
  content: "\f0f7";
}
.uk-icon-hospital-o:before {
  content: "\f0f8";
}
.uk-icon-ambulance:before {
  content: "\f0f9";
}
.uk-icon-medkit:before {
  content: "\f0fa";
}
.uk-icon-fighter-jet:before {
  content: "\f0fb";
}
.uk-icon-beer:before {
  content: "\f0fc";
}
.uk-icon-h-square:before {
  content: "\f0fd";
}
.uk-icon-plus-square:before {
  content: "\f0fe";
}
.uk-icon-angle-double-left:before {
  content: "\f100";
}
.uk-icon-angle-double-right:before {
  content: "\f101";
}
.uk-icon-angle-double-up:before {
  content: "\f102";
}
.uk-icon-angle-double-down:before {
  content: "\f103";
}
.uk-icon-angle-left:before {
  content: "\f104";
}
.uk-icon-angle-right:before {
  content: "\f105";
}
.uk-icon-angle-up:before {
  content: "\f106";
}
.uk-icon-angle-down:before {
  content: "\f107";
}
.uk-icon-desktop:before {
  content: "\f108";
}
.uk-icon-laptop:before {
  content: "\f109";
}
.uk-icon-tablet:before {
  content: "\f10a";
}
.uk-icon-mobile-phone:before,
.uk-icon-mobile:before {
  content: "\f10b";
}
.uk-icon-circle-o:before {
  content: "\f10c";
}
.uk-icon-quote-left:before {
  content: "\f10d";
}
.uk-icon-quote-right:before {
  content: "\f10e";
}
.uk-icon-spinner:before {
  content: "\f110";
}
.uk-icon-circle:before {
  content: "\f111";
}
.uk-icon-mail-reply:before,
.uk-icon-reply:before {
  content: "\f112";
}
.uk-icon-github-alt:before {
  content: "\f113";
}
.uk-icon-folder-o:before {
  content: "\f114";
}
.uk-icon-folder-open-o:before {
  content: "\f115";
}
.uk-icon-smile-o:before {
  content: "\f118";
}
.uk-icon-frown-o:before {
  content: "\f119";
}
.uk-icon-meh-o:before {
  content: "\f11a";
}
.uk-icon-gamepad:before {
  content: "\f11b";
}
.uk-icon-keyboard-o:before {
  content: "\f11c";
}
.uk-icon-flag-o:before {
  content: "\f11d";
}
.uk-icon-flag-checkered:before {
  content: "\f11e";
}
.uk-icon-terminal:before {
  content: "\f120";
}
.uk-icon-code:before {
  content: "\f121";
}
.uk-icon-mail-reply-all:before,
.uk-icon-reply-all:before {
  content: "\f122";
}
.uk-icon-star-half-empty:before,
.uk-icon-star-half-full:before,
.uk-icon-star-half-o:before {
  content: "\f123";
}
.uk-icon-location-arrow:before {
  content: "\f124";
}
.uk-icon-crop:before {
  content: "\f125";
}
.uk-icon-code-fork:before {
  content: "\f126";
}
.uk-icon-unlink:before,
.uk-icon-chain-broken:before {
  content: "\f127";
}
.uk-icon-question:before {
  content: "\f128";
}
.uk-icon-info:before {
  content: "\f129";
}
.uk-icon-exclamation:before {
  content: "\f12a";
}
.uk-icon-superscript:before {
  content: "\f12b";
}
.uk-icon-subscript:before {
  content: "\f12c";
}
.uk-icon-eraser:before {
  content: "\f12d";
}
.uk-icon-puzzle-piece:before {
  content: "\f12e";
}
.uk-icon-microphone:before {
  content: "\f130";
}
.uk-icon-microphone-slash:before {
  content: "\f131";
}
.uk-icon-shield:before {
  content: "\f132";
}
.uk-icon-calendar-o:before {
  content: "\f133";
}
.uk-icon-fire-extinguisher:before {
  content: "\f134";
}
.uk-icon-rocket:before {
  content: "\f135";
}
.uk-icon-maxcdn:before {
  content: "\f136";
}
.uk-icon-chevron-circle-left:before {
  content: "\f137";
}
.uk-icon-chevron-circle-right:before {
  content: "\f138";
}
.uk-icon-chevron-circle-up:before {
  content: "\f139";
}
.uk-icon-chevron-circle-down:before {
  content: "\f13a";
}
.uk-icon-html5:before {
  content: "\f13b";
}
.uk-icon-css3:before {
  content: "\f13c";
}
.uk-icon-anchor:before {
  content: "\f13d";
}
.uk-icon-unlock-alt:before {
  content: "\f13e";
}
.uk-icon-bullseye:before {
  content: "\f140";
}
.uk-icon-ellipsis-h:before {
  content: "\f141";
}
.uk-icon-ellipsis-v:before {
  content: "\f142";
}
.uk-icon-rss-square:before {
  content: "\f143";
}
.uk-icon-play-circle:before {
  content: "\f144";
}
.uk-icon-ticket:before {
  content: "\f145";
}
.uk-icon-minus-square:before {
  content: "\f146";
}
.uk-icon-minus-square-o:before {
  content: "\f147";
}
.uk-icon-level-up:before {
  content: "\f148";
}
.uk-icon-level-down:before {
  content: "\f149";
}
.uk-icon-check-square:before {
  content: "\f14a";
}
.uk-icon-pencil-square:before {
  content: "\f14b";
}
.uk-icon-external-link-square:before {
  content: "\f14c";
}
.uk-icon-share-square:before {
  content: "\f14d";
}
.uk-icon-compass:before {
  content: "\f14e";
}
.uk-icon-toggle-down:before,
.uk-icon-caret-square-o-down:before {
  content: "\f150";
}
.uk-icon-toggle-up:before,
.uk-icon-caret-square-o-up:before {
  content: "\f151";
}
.uk-icon-toggle-right:before,
.uk-icon-caret-square-o-right:before {
  content: "\f152";
}
.uk-icon-euro:before,
.uk-icon-eur:before {
  content: "\f153";
}
.uk-icon-gbp:before {
  content: "\f154";
}
.uk-icon-dollar:before,
.uk-icon-usd:before {
  content: "\f155";
}
.uk-icon-rupee:before,
.uk-icon-inr:before {
  content: "\f156";
}
.uk-icon-cny:before,
.uk-icon-rmb:before,
.uk-icon-yen:before,
.uk-icon-jpy:before {
  content: "\f157";
}
.uk-icon-ruble:before,
.uk-icon-rouble:before,
.uk-icon-rub:before {
  content: "\f158";
}
.uk-icon-won:before,
.uk-icon-krw:before {
  content: "\f159";
}
.uk-icon-bitcoin:before,
.uk-icon-btc:before {
  content: "\f15a";
}
.uk-icon-file:before {
  content: "\f15b";
}
.uk-icon-file-text:before {
  content: "\f15c";
}
.uk-icon-sort-alpha-asc:before {
  content: "\f15d";
}
.uk-icon-sort-alpha-desc:before {
  content: "\f15e";
}
.uk-icon-sort-amount-asc:before {
  content: "\f160";
}
.uk-icon-sort-amount-desc:before {
  content: "\f161";
}
.uk-icon-sort-numeric-asc:before {
  content: "\f162";
}
.uk-icon-sort-numeric-desc:before {
  content: "\f163";
}
.uk-icon-thumbs-up:before {
  content: "\f164";
}
.uk-icon-thumbs-down:before {
  content: "\f165";
}
.uk-icon-youtube-square:before {
  content: "\f166";
}
.uk-icon-youtube:before {
  content: "\f167";
}
.uk-icon-xing:before {
  content: "\f168";
}
.uk-icon-xing-square:before {
  content: "\f169";
}
.uk-icon-youtube-play:before {
  content: "\f16a";
}
.uk-icon-dropbox:before {
  content: "\f16b";
}
.uk-icon-stack-overflow:before {
  content: "\f16c";
}
.uk-icon-instagram:before {
  content: "\f16d";
}
.uk-icon-flickr:before {
  content: "\f16e";
}
.uk-icon-adn:before {
  content: "\f170";
}
.uk-icon-bitbucket:before {
  content: "\f171";
}
.uk-icon-bitbucket-square:before {
  content: "\f172";
}
.uk-icon-tumblr:before {
  content: "\f173";
}
.uk-icon-tumblr-square:before {
  content: "\f174";
}
.uk-icon-long-arrow-down:before {
  content: "\f175";
}
.uk-icon-long-arrow-up:before {
  content: "\f176";
}
.uk-icon-long-arrow-left:before {
  content: "\f177";
}
.uk-icon-long-arrow-right:before {
  content: "\f178";
}
.uk-icon-apple:before {
  content: "\f179";
}
.uk-icon-windows:before {
  content: "\f17a";
}
.uk-icon-android:before {
  content: "\f17b";
}
.uk-icon-linux:before {
  content: "\f17c";
}
.uk-icon-dribbble:before {
  content: "\f17d";
}
.uk-icon-skype:before {
  content: "\f17e";
}
.uk-icon-foursquare:before {
  content: "\f180";
}
.uk-icon-trello:before {
  content: "\f181";
}
.uk-icon-female:before {
  content: "\f182";
}
.uk-icon-male:before {
  content: "\f183";
}
.uk-icon-gittip:before,
.uk-icon-gratipay:before {
  content: "\f184";
}
.uk-icon-sun-o:before {
  content: "\f185";
}
.uk-icon-moon-o:before {
  content: "\f186";
}
.uk-icon-archive:before {
  content: "\f187";
}
.uk-icon-bug:before {
  content: "\f188";
}
.uk-icon-vk:before {
  content: "\f189";
}
.uk-icon-weibo:before {
  content: "\f18a";
}
.uk-icon-renren:before {
  content: "\f18b";
}
.uk-icon-pagelines:before {
  content: "\f18c";
}
.uk-icon-stack-exchange:before {
  content: "\f18d";
}
.uk-icon-arrow-circle-o-right:before {
  content: "\f18e";
}
.uk-icon-arrow-circle-o-left:before {
  content: "\f190";
}
.uk-icon-toggle-left:before,
.uk-icon-caret-square-o-left:before {
  content: "\f191";
}
.uk-icon-dot-circle-o:before {
  content: "\f192";
}
.uk-icon-wheelchair:before {
  content: "\f193";
}
.uk-icon-vimeo-square:before {
  content: "\f194";
}
.uk-icon-turkish-lira:before,
.uk-icon-try:before {
  content: "\f195";
}
.uk-icon-plus-square-o:before {
  content: "\f196";
}
.uk-icon-space-shuttle:before {
  content: "\f197";
}
.uk-icon-slack:before {
  content: "\f198";
}
.uk-icon-envelope-square:before {
  content: "\f199";
}
.uk-icon-wordpress:before {
  content: "\f19a";
}
.uk-icon-openid:before {
  content: "\f19b";
}
.uk-icon-institution:before,
.uk-icon-bank:before,
.uk-icon-university:before {
  content: "\f19c";
}
.uk-icon-mortar-board:before,
.uk-icon-graduation-cap:before {
  content: "\f19d";
}
.uk-icon-yahoo:before {
  content: "\f19e";
}
.uk-icon-google:before {
  content: "\f1a0";
}
.uk-icon-reddit:before {
  content: "\f1a1";
}
.uk-icon-reddit-square:before {
  content: "\f1a2";
}
.uk-icon-stumbleupon-circle:before {
  content: "\f1a3";
}
.uk-icon-stumbleupon:before {
  content: "\f1a4";
}
.uk-icon-delicious:before {
  content: "\f1a5";
}
.uk-icon-digg:before {
  content: "\f1a6";
}
.uk-icon-pied-piper:before {
  content: "\f1a7";
}
.uk-icon-pied-piper-alt:before {
  content: "\f1a8";
}
.uk-icon-drupal:before {
  content: "\f1a9";
}
.uk-icon-joomla:before {
  content: "\f1aa";
}
.uk-icon-language:before {
  content: "\f1ab";
}
.uk-icon-fax:before {
  content: "\f1ac";
}
.uk-icon-building:before {
  content: "\f1ad";
}
.uk-icon-child:before {
  content: "\f1ae";
}
.uk-icon-paw:before {
  content: "\f1b0";
}
.uk-icon-spoon:before {
  content: "\f1b1";
}
.uk-icon-cube:before {
  content: "\f1b2";
}
.uk-icon-cubes:before {
  content: "\f1b3";
}
.uk-icon-behance:before {
  content: "\f1b4";
}
.uk-icon-behance-square:before {
  content: "\f1b5";
}
.uk-icon-steam:before {
  content: "\f1b6";
}
.uk-icon-steam-square:before {
  content: "\f1b7";
}
.uk-icon-recycle:before {
  content: "\f1b8";
}
.uk-icon-automobile:before,
.uk-icon-car:before {
  content: "\f1b9";
}
.uk-icon-cab:before,
.uk-icon-taxi:before {
  content: "\f1ba";
}
.uk-icon-tree:before {
  content: "\f1bb";
}
.uk-icon-spotify:before {
  content: "\f1bc";
}
.uk-icon-deviantart:before {
  content: "\f1bd";
}
.uk-icon-soundcloud:before {
  content: "\f1be";
}
.uk-icon-database:before {
  content: "\f1c0";
}
.uk-icon-file-pdf-o:before {
  content: "\f1c1";
}
.uk-icon-file-word-o:before {
  content: "\f1c2";
}
.uk-icon-file-excel-o:before {
  content: "\f1c3";
}
.uk-icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.uk-icon-file-photo-o:before,
.uk-icon-file-picture-o:before,
.uk-icon-file-image-o:before {
  content: "\f1c5";
}
.uk-icon-file-zip-o:before,
.uk-icon-file-archive-o:before {
  content: "\f1c6";
}
.uk-icon-file-sound-o:before,
.uk-icon-file-audio-o:before {
  content: "\f1c7";
}
.uk-icon-file-movie-o:before,
.uk-icon-file-video-o:before {
  content: "\f1c8";
}
.uk-icon-file-code-o:before {
  content: "\f1c9";
}
.uk-icon-vine:before {
  content: "\f1ca";
}
.uk-icon-codepen:before {
  content: "\f1cb";
}
.uk-icon-jsfiddle:before {
  content: "\f1cc";
}
.uk-icon-life-bouy:before,
.uk-icon-life-buoy:before,
.uk-icon-life-saver:before,
.uk-icon-support:before,
.uk-icon-life-ring:before {
  content: "\f1cd";
}
.uk-icon-circle-o-notch:before {
  content: "\f1ce";
}
.uk-icon-ra:before,
.uk-icon-rebel:before {
  content: "\f1d0";
}
.uk-icon-ge:before,
.uk-icon-empire:before {
  content: "\f1d1";
}
.uk-icon-git-square:before {
  content: "\f1d2";
}
.uk-icon-git:before {
  content: "\f1d3";
}
.uk-icon-hacker-news:before {
  content: "\f1d4";
}
.uk-icon-tencent-weibo:before {
  content: "\f1d5";
}
.uk-icon-qq:before {
  content: "\f1d6";
}
.uk-icon-wechat:before,
.uk-icon-weixin:before {
  content: "\f1d7";
}
.uk-icon-send:before,
.uk-icon-paper-plane:before {
  content: "\f1d8";
}
.uk-icon-send-o:before,
.uk-icon-paper-plane-o:before {
  content: "\f1d9";
}
.uk-icon-history:before {
  content: "\f1da";
}
.uk-icon-genderless:before,
.uk-icon-circle-thin:before {
  content: "\f1db";
}
.uk-icon-header:before {
  content: "\f1dc";
}
.uk-icon-paragraph:before {
  content: "\f1dd";
}
.uk-icon-sliders:before {
  content: "\f1de";
}
.uk-icon-share-alt:before {
  content: "\f1e0";
}
.uk-icon-share-alt-square:before {
  content: "\f1e1";
}
.uk-icon-bomb:before {
  content: "\f1e2";
}
.uk-icon-soccer-ball-o:before,
.uk-icon-futbol-o:before {
  content: "\f1e3";
}
.uk-icon-tty:before {
  content: "\f1e4";
}
.uk-icon-binoculars:before {
  content: "\f1e5";
}
.uk-icon-plug:before {
  content: "\f1e6";
}
.uk-icon-slideshare:before {
  content: "\f1e7";
}
.uk-icon-twitch:before {
  content: "\f1e8";
}
.uk-icon-yelp:before {
  content: "\f1e9";
}
.uk-icon-newspaper-o:before {
  content: "\f1ea";
}
.uk-icon-wifi:before {
  content: "\f1eb";
}
.uk-icon-calculator:before {
  content: "\f1ec";
}
.uk-icon-paypal:before {
  content: "\f1ed";
}
.uk-icon-google-wallet:before {
  content: "\f1ee";
}
.uk-icon-cc-visa:before {
  content: "\f1f0";
}
.uk-icon-cc-mastercard:before {
  content: "\f1f1";
}
.uk-icon-cc-discover:before {
  content: "\f1f2";
}
.uk-icon-cc-amex:before {
  content: "\f1f3";
}
.uk-icon-cc-paypal:before {
  content: "\f1f4";
}
.uk-icon-cc-stripe:before {
  content: "\f1f5";
}
.uk-icon-bell-slash:before {
  content: "\f1f6";
}
.uk-icon-bell-slash-o:before {
  content: "\f1f7";
}
.uk-icon-trash:before {
  content: "\f1f8";
}
.uk-icon-copyright:before {
  content: "\f1f9";
}
.uk-icon-at:before {
  content: "\f1fa";
}
.uk-icon-eyedropper:before {
  content: "\f1fb";
}
.uk-icon-paint-brush:before {
  content: "\f1fc";
}
.uk-icon-birthday-cake:before {
  content: "\f1fd";
}
.uk-icon-area-chart:before {
  content: "\f1fe";
}
.uk-icon-pie-chart:before {
  content: "\f200";
}
.uk-icon-line-chart:before {
  content: "\f201";
}
.uk-icon-lastfm:before {
  content: "\f202";
}
.uk-icon-lastfm-square:before {
  content: "\f203";
}
.uk-icon-toggle-off:before {
  content: "\f204";
}
.uk-icon-toggle-on:before {
  content: "\f205";
}
.uk-icon-bicycle:before {
  content: "\f206";
}
.uk-icon-bus:before {
  content: "\f207";
}
.uk-icon-ioxhost:before {
  content: "\f208";
}
.uk-icon-angellist:before {
  content: "\f209";
}
.uk-icon-cc:before {
  content: "\f20a";
}
.uk-icon-shekel:before,
.uk-icon-sheqel:before,
.uk-icon-ils:before {
  content: "\f20b";
}
.uk-icon-meanpath:before {
  content: "\f20c";
}
.uk-icon-buysellads:before {
  content: "\f20d";
}
.uk-icon-connectdevelop:before {
  content: "\f20e";
}
.uk-icon-dashcube:before {
  content: "\f210";
}
.uk-icon-forumbee:before {
  content: "\f211";
}
.uk-icon-leanpub:before {
  content: "\f212";
}
.uk-icon-sellsy:before {
  content: "\f213";
}
.uk-icon-shirtsinbulk:before {
  content: "\f214";
}
.uk-icon-simplybuilt:before {
  content: "\f215";
}
.uk-icon-skyatlas:before {
  content: "\f216";
}
.uk-icon-cart-plus:before {
  content: "\f217";
}
.uk-icon-cart-arrow-down:before {
  content: "\f218";
}
.uk-icon-diamond:before {
  content: "\f219";
}
.uk-icon-ship:before {
  content: "\f21a";
}
.uk-icon-user-secret:before {
  content: "\f21b";
}
.uk-icon-motorcycle:before {
  content: "\f21c";
}
.uk-icon-street-view:before {
  content: "\f21d";
}
.uk-icon-heartbeat:before {
  content: "\f21e";
}
.uk-icon-venus:before {
  content: "\f221";
}
.uk-icon-mars:before {
  content: "\f222";
}
.uk-icon-mercury:before {
  content: "\f223";
}
.uk-icon-transgender:before {
  content: "\f224";
}
.uk-icon-transgender-alt:before {
  content: "\f225";
}
.uk-icon-venus-double:before {
  content: "\f226";
}
.uk-icon-mars-double:before {
  content: "\f227";
}
.uk-icon-venus-mars:before {
  content: "\f228";
}
.uk-icon-mars-stroke:before {
  content: "\f229";
}
.uk-icon-mars-stroke-v:before {
  content: "\f22a";
}
.uk-icon-mars-stroke-h:before {
  content: "\f22b";
}
.uk-icon-neuter:before {
  content: "\f22c";
}
.uk-icon-facebook-official:before {
  content: "\f230";
}
.uk-icon-pinterest-p:before {
  content: "\f231";
}
.uk-icon-whatsapp:before {
  content: "\f232";
}
.uk-icon-server:before {
  content: "\f233";
}
.uk-icon-user-plus:before {
  content: "\f234";
}
.uk-icon-user-times:before {
  content: "\f235";
}
.uk-icon-hotel:before,
.uk-icon-bed:before {
  content: "\f236";
}
.uk-icon-viacoin:before {
  content: "\f237";
}
.uk-icon-train:before {
  content: "\f238";
}
.uk-icon-subway:before {
  content: "\f239";
}
.uk-icon-medium-logo:before {
  content: "\f23a";
}
.uk-icon-500px:before {
  content: "\f26e";
}
.uk-icon-amazon:before {
  content: "\f270";
}
.uk-icon-balance-scale:before {
  content: "\f24e";
}
.uk-icon-battery-empty:before,
.uk-icon-battery-0:before {
  content: "\f244";
}
.uk-icon-battery-quarter:before,
.uk-icon-battery-1:before {
  content: "\f243";
}
.uk-icon-battery-half:before,
.uk-icon-battery-2:before {
  content: "\f242";
}
.uk-icon-battery-three-quarters:before,
.uk-icon-battery-3:before {
  content: "\f241";
}
.uk-icon-battery-full:before,
.uk-icon-battery-4:before {
  content: "\f240";
}
.uk-icon-black-tie:before {
  content: "\f27e";
}
.uk-icon-calendar-check-o:before {
  content: "\f274";
}
.uk-icon-calendar-minus-o:before {
  content: "\f272";
}
.uk-icon-calendar-plus-o:before {
  content: "\f271";
}
.uk-icon-calendar-times-o:before {
  content: "\f273";
}
.uk-icon-cc-diners-club:before {
  content: "\f24c";
}
.uk-icon-cc-jcb:before {
  content: "\f24b";
}
.uk-icon-chrome:before {
  content: "\f268";
}
.uk-icon-clone:before {
  content: "\f24d";
}
.uk-icon-commenting:before {
  content: "\f27a";
}
.uk-icon-commenting-o:before {
  content: "\f27b";
}
.uk-icon-contao:before {
  content: "\f26d";
}
.uk-icon-creative-commons:before {
  content: "\f25e";
}
.uk-icon-expeditedssl:before {
  content: "\f23e";
}
.uk-icon-firefox:before {
  content: "\f269";
}
.uk-icon-fonticons:before {
  content: "\f280";
}
.uk-icon-get-pocket:before {
  content: "\f265";
}
.uk-icon-gg:before {
  content: "\f260";
}
.uk-icon-gg-circle:before {
  content: "\f261";
}
.uk-icon-hand-lizard-o:before {
  content: "\f258";
}
.uk-icon-hand-stop-o:before,
.uk-icon-hand-paper-o:before {
  content: "\f256";
}
.uk-icon-hand-peace-o:before {
  content: "\f25b";
}
.uk-icon-hand-pointer-o:before {
  content: "\f25a";
}
.uk-icon-hand-grab-o:before,
.uk-icon-hand-rock-o:before {
  content: "\f255";
}
.uk-icon-hand-scissors-o:before {
  content: "\f257";
}
.uk-icon-hand-spock-o:before {
  content: "\f259";
}
.uk-icon-hourglass:before {
  content: "\f254";
}
.uk-icon-hourglass-o:before {
  content: "\f250";
}
.uk-icon-hourglass-1:before,
.uk-icon-hourglass-start:before {
  content: "\f251";
}
.uk-icon-hourglass-2:before,
.uk-icon-hourglass-half:before {
  content: "\f252";
}
.uk-icon-hourglass-3:before,
.uk-icon-hourglass-end:before {
  content: "\f253";
}
.uk-icon-houzz:before {
  content: "\f27c";
}
.uk-icon-i-cursor:before {
  content: "\f246";
}
.uk-icon-industry:before {
  content: "\f275";
}
.uk-icon-internet-explorer:before {
  content: "\f26b";
}
.uk-icon-map:before {
  content: "\f279";
}
.uk-icon-map-o:before {
  content: "\f278";
}
.uk-icon-map-pin:before {
  content: "\f276";
}
.uk-icon-map-signs:before {
  content: "\f277";
}
.uk-icon-mouse-pointer:before {
  content: "\f245";
}
.uk-icon-object-group:before {
  content: "\f247";
}
.uk-icon-object-ungroup:before {
  content: "\f248";
}
.uk-icon-odnoklassniki:before {
  content: "\f263";
}
.uk-icon-odnoklassniki-square:before {
  content: "\f264";
}
.uk-icon-opencart:before {
  content: "\f23d";
}
.uk-icon-opera:before {
  content: "\f26a";
}
.uk-icon-optin-monster:before {
  content: "\f23c";
}
.uk-icon-registered:before {
  content: "\f25d";
}
.uk-icon-safari:before {
  content: "\f267";
}
.uk-icon-sticky-note:before {
  content: "\f249";
}
.uk-icon-sticky-note-o:before {
  content: "\f24a";
}
.uk-icon-tv:before,
.uk-icon-television:before {
  content: "\f26c";
}
.uk-icon-trademark:before {
  content: "\f25c";
}
.uk-icon-tripadvisor:before {
  content: "\f262";
}
.uk-icon-vimeo:before {
  content: "\f27d";
}
.uk-icon-wikipedia-w:before {
  content: "\f266";
}
.uk-icon-yc:before,
.uk-icon-y-combinator:before {
  content: "\f23b";
}
.uk-icon-yc-square:before,
.uk-icon-y-combinator-square:before {
  content: "\f1d4";
}
.uk-icon-bluetooth:before {
  content: "\f293";
}
.uk-icon-bluetooth-b:before {
  content: "\f294";
}
.uk-icon-codiepie:before {
  content: "\f284";
}
.uk-icon-credit-card-alt:before {
  content: "\f283";
}
.uk-icon-edge:before {
  content: "\f282";
}
.uk-icon-fort-awesome:before {
  content: "\f286";
}
.uk-icon-hashtag:before {
  content: "\f292";
}
.uk-icon-mixcloud:before {
  content: "\f289";
}
.uk-icon-modx:before {
  content: "\f285";
}
.uk-icon-pause-circle:before {
  content: "\f28b";
}
.uk-icon-pause-circle-o:before {
  content: "\f28c";
}
.uk-icon-percent:before {
  content: "\f295";
}
.uk-icon-product-hunt:before {
  content: "\f288";
}
.uk-icon-reddit-alien:before {
  content: "\f281";
}
.uk-icon-scribd:before {
  content: "\f28a";
}
.uk-icon-shopping-bag:before {
  content: "\f290";
}
.uk-icon-shopping-basket:before {
  content: "\f291";
}
.uk-icon-stop-circle:before {
  content: "\f28d";
}
.uk-icon-stop-circle-o:before {
  content: "\f28e";
}
.uk-icon-usb:before {
  content: "\f287";
}
.uk-close::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.uk-close {
  -webkit-appearance: none;
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0;
  background: transparent;
  display: inline-block;
  box-sizing: content-box;
  width: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  opacity: 0.3;
}
.uk-close:after {
  display: block;
  content: "\f00d";
  font-family: FontAwesome;
}
.uk-close:hover,
.uk-close:focus {
  opacity: 0.5;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.uk-close-alt {
  padding: 2px;
  border-radius: 50%;
  background: #62686d;
  opacity: 1;
}
.uk-close-alt:hover,
.uk-close-alt:focus {
  opacity: 1;
}
.uk-close-alt:after {
  opacity: 0.5;
}
.uk-close-alt:hover:after,
.uk-close-alt:focus:after {
  opacity: 0.8;
}
.uk-badge {
  display: inline-block;
  padding: 0 5px;
  background: #7a8288;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  text-transform: none;
}
a.uk-badge:hover {
  color: #ffffff;
}
.uk-badge-notification {
  box-sizing: border-box;
  min-width: 18px;
  border-radius: 500px;
  font-size: 12px;
  line-height: 18px;
}
.uk-badge-success {
  background-color: #62c462;
}
.uk-badge-warning {
  background-color: #f89406;
}
.uk-badge-danger {
  background-color: #ee5f5b;
}
.uk-alert {
  margin-bottom: 15px;
  padding: 10px;
  background: #5bc0de;
  color: #ffffff;
}
* + .uk-alert {
  margin-top: 15px;
}
.uk-alert > :last-child {
  margin-bottom: 0;
}
.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 {
  color: inherit;
}
.uk-alert > .uk-close:first-child {
  float: right;
}
.uk-alert > .uk-close:first-child + * {
  margin-top: 0;
}
.uk-alert-success {
  background: #62c462;
  color: #ffffff;
}
.uk-alert-warning {
  background: #f89406;
  color: #ffffff;
}
.uk-alert-danger {
  background: #ee5f5b;
  color: #ffffff;
}
.uk-alert-large {
  padding: 20px;
}
.uk-alert-large > .uk-close:first-child {
  margin: -10px -10px 0 0;
}
.uk-thumbnail {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  border: 1px solid #141619;
  background: #272b30;
}
a.uk-thumbnail:hover,
a.uk-thumbnail:focus {
  border-color: #000000;
  background-color: #272b30;
  text-decoration: none;
  outline: none;
}
.uk-thumbnail-caption {
  padding-top: 4px;
  text-align: center;
  color: #c8c8c8;
}
.uk-thumbnail-mini {
  width: 150px;
}
.uk-thumbnail-small {
  width: 200px;
}
.uk-thumbnail-medium {
  width: 300px;
}
.uk-thumbnail-large {
  width: 400px;
}
.uk-thumbnail-expand,
.uk-thumbnail-expand > img {
  width: 100%;
}
.uk-overlay {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  margin: 0;
}
.uk-overlay.uk-border-circle {
  -webkit-mask-image: -webkit-radial-gradient(circle, #ffffff 100%, #000000 100%);
}
.uk-overlay > :first-child {
  margin-bottom: 0;
}
.uk-overlay-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #ffffff;
}
.uk-overlay-panel > :last-child,
.uk-overlay-panel.uk-flex > * > :last-child {
  margin-bottom: 0;
}
.uk-overlay-panel h1,
.uk-overlay-panel h2,
.uk-overlay-panel h3,
.uk-overlay-panel h4,
.uk-overlay-panel h5,
.uk-overlay-panel h6 {
  color: inherit;
}
.uk-overlay-panel a:not([class]) {
  color: inherit;
  text-decoration: underline;
}
.uk-overlay-panel a[class*='uk-icon-']:not(.uk-icon-button) {
  color: inherit;
}
.uk-overlay-hover:not(:hover):not(.uk-hover) .uk-overlay-panel:not(.uk-ignore) {
  opacity: 0;
}
.uk-overlay-active :not(.uk-active) > .uk-overlay-panel:not(.uk-ignore) {
  opacity: 0;
}
.uk-overlay-background {
  background: rgba(0, 0, 0, 0.5);
}
.uk-overlay-image {
  padding: 0;
}
.uk-overlay-top {
  bottom: auto;
}
.uk-overlay-bottom {
  top: auto;
}
.uk-overlay-left {
  right: auto;
}
.uk-overlay-right {
  left: auto;
}
.uk-overlay-icon:before {
  content: "\f002";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 50px;
  line-height: 1;
  font-family: FontAwesome;
  text-align: center;
  color: #ffffff;
}
.uk-overlay-fade,
.uk-overlay-scale,
.uk-overlay-spin,
.uk-overlay-grayscale,
.uk-overlay-blur,
[class*='uk-overlay-slide'] {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-property: opacity, transform, filter;
}
.uk-overlay-active .uk-overlay-fade,
.uk-overlay-active .uk-overlay-scale,
.uk-overlay-active .uk-overlay-spin,
.uk-overlay-active [class*='uk-overlay-slide'] {
  transition-duration: 0.8s;
}
.uk-overlay-fade {
  opacity: 0.7;
}
.uk-overlay-hover:hover .uk-overlay-fade,
.uk-overlay-hover.uk-hover .uk-overlay-fade,
.uk-overlay-active .uk-active > .uk-overlay-fade {
  opacity: 1;
}
.uk-overlay-scale {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.uk-overlay-hover:hover .uk-overlay-scale,
.uk-overlay-hover.uk-hover .uk-overlay-scale,
.uk-overlay-active .uk-active > .uk-overlay-scale {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.uk-overlay-spin {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.uk-overlay-hover:hover .uk-overlay-spin,
.uk-overlay-hover.uk-hover .uk-overlay-spin,
.uk-overlay-active .uk-active > .uk-overlay-spin {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}
.uk-overlay-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.uk-overlay-hover:hover .uk-overlay-grayscale,
.uk-overlay-hover.uk-hover .uk-overlay-grayscale,
.uk-overlay-active .uk-active > .uk-overlay-grayscale {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
[class*='uk-overlay-slide'] {
  opacity: 0;
}
.uk-overlay-slide-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.uk-overlay-slide-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.uk-overlay-slide-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.uk-overlay-slide-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.uk-overlay-hover:hover [class*='uk-overlay-slide'],
.uk-overlay-hover.uk-hover [class*='uk-overlay-slide'],
.uk-overlay-active .uk-active > [class*='uk-overlay-slide'] {
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}
.uk-overlay-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  -webkit-transform: translate3d(0, 0, 0);
}
.uk-overlay:hover .uk-overlay-area,
.uk-overlay.uk-hover .uk-overlay-area,
.uk-overlay-toggle:hover .uk-overlay-area,
.uk-overlay-toggle.uk-hover .uk-overlay-area {
  opacity: 1;
}
.uk-overlay-area:empty:before {
  content: "\f002";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 50px;
  line-height: 1;
  font-family: FontAwesome;
  text-align: center;
  color: #ffffff;
}
.uk-overlay-area:not(:empty) {
  font-size: 0.001px;
}
.uk-overlay-area:not(:empty):before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.uk-overlay-area-content {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
  font-size: 1rem;
  text-align: center;
  padding: 0 15px;
  color: #ffffff;
}
.uk-overlay-area-content > :last-child {
  margin-bottom: 0;
}
.uk-overlay-area-content a:not([class]),
.uk-overlay-area-content a:not([class]):hover {
  color: inherit;
}
.uk-overlay-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  -webkit-transform: translate3d(0, 0, 0);
}
.uk-overlay:hover .uk-overlay-caption,
.uk-overlay.uk-hover .uk-overlay-caption,
.uk-overlay-toggle:hover .uk-overlay-caption,
.uk-overlay-toggle.uk-hover .uk-overlay-caption {
  opacity: 1;
}
[class*='uk-column-'] {
  -webkit-column-gap: 25px;
  -moz-column-gap: 25px;
  column-gap: 25px;
}
.uk-column-1-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.uk-column-1-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.uk-column-1-4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
.uk-column-1-5 {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}
.uk-column-1-6 {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
}
@media (min-width: 480px) {
  .uk-column-small-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-small-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-small-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-small-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-small-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
@media (min-width: 768px) {
  .uk-column-medium-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-medium-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-medium-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-medium-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-medium-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
@media (min-width: 960px) {
  .uk-column-large-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-large-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-large-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-large-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-large-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
@media (min-width: 1220px) {
  .uk-column-xlarge-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-xlarge-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-xlarge-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-xlarge-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-xlarge-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
[class*='uk-animation-'] {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@media screen {
  [data-uk-scrollspy*='uk-animation-']:not([data-uk-scrollspy*='target']) {
    opacity: 0;
  }
}
.uk-animation-fade {
  -webkit-animation-name: uk-fade;
  animation-name: uk-fade;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
}
.uk-animation-scale-up {
  -webkit-animation-name: uk-fade-scale-02;
  animation-name: uk-fade-scale-02;
}
.uk-animation-scale-down {
  -webkit-animation-name: uk-fade-scale-18;
  animation-name: uk-fade-scale-18;
}
.uk-animation-slide-top {
  -webkit-animation-name: uk-fade-top;
  animation-name: uk-fade-top;
}
.uk-animation-slide-bottom {
  -webkit-animation-name: uk-fade-bottom;
  animation-name: uk-fade-bottom;
}
.uk-animation-slide-left {
  -webkit-animation-name: uk-fade-left;
  animation-name: uk-fade-left;
}
.uk-animation-slide-right {
  -webkit-animation-name: uk-fade-right;
  animation-name: uk-fade-right;
}
.uk-animation-scale {
  -webkit-animation-name: uk-scale-12;
  animation-name: uk-scale-12;
}
.uk-animation-shake {
  -webkit-animation-name: uk-shake;
  animation-name: uk-shake;
}
.uk-animation-reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.uk-animation-15 {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
}
.uk-animation-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.uk-animation-top-center {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.uk-animation-top-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.uk-animation-middle-left {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.uk-animation-middle-right {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.uk-animation-bottom-left {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.uk-animation-bottom-center {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.uk-animation-bottom-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.uk-animation-hover:not(:hover),
.uk-animation-hover:not(:hover) [class*='uk-animation-'],
.uk-touch .uk-animation-hover:not(.uk-hover),
.uk-touch .uk-animation-hover:not(.uk-hover) [class*='uk-animation-'] {
  -webkit-animation-name: none;
  animation-name: none;
}
@-webkit-keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes uk-fade-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes uk-fade-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-fade-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes uk-fade-scale-15 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes uk-fade-scale-15 {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes uk-slide-left {
  0% {
    -webkit-transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-slide-right {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-slide-left-33 {
  0% {
    -webkit-transform: translateX(33%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-left-33 {
  0% {
    transform: translateX(33%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-slide-right-33 {
  0% {
    -webkit-transform: translateX(-33%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-right-33 {
  0% {
    transform: translateX(-33%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-scale-12 {
  0% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes uk-scale-12 {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes uk-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes uk-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes uk-shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-9px);
  }
  20% {
    -webkit-transform: translateX(8px);
  }
  30% {
    -webkit-transform: translateX(-7px);
  }
  40% {
    -webkit-transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-5px);
  }
  60% {
    -webkit-transform: translateX(4px);
  }
  70% {
    -webkit-transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-1px);
  }
}
@keyframes uk-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
@-webkit-keyframes uk-slide-top-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-slide-top-fixed {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes uk-slide-bottom-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-fixed {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.uk-dropdown,
.uk-dropdown-blank {
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  width: 200px;
}
.uk-dropdown {
  padding: 15px;
  background: #3a3f44;
  color: #c8c8c8;
  font-size: 1rem;
  vertical-align: top;
}
.uk-open > .uk-dropdown,
.uk-open > .uk-dropdown-blank {
  display: block;
  -webkit-animation: uk-fade 0.2s ease-in-out;
  animation: uk-fade 0.2s ease-in-out;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.uk-dropdown-top {
  margin-top: -5px;
}
.uk-dropdown-bottom {
  margin-top: 5px;
}
.uk-dropdown-left {
  margin-left: -5px;
}
.uk-dropdown-right {
  margin-left: 5px;
}
.uk-dropdown .uk-nav {
  margin: 0 -15px;
}
.uk-grid .uk-dropdown-grid + .uk-dropdown-grid {
  margin-top: 15px;
}
.uk-dropdown-grid > [class*='uk-width-'] > .uk-panel + .uk-panel {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid {
    margin-left: -15px;
    margin-right: -15px;
  }
  .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid > [class*='uk-width-'] {
    padding-left: 15px;
    padding-right: 15px;
  }
  .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid > [class*='uk-width-']:nth-child(n+2) {
    border-left: 1px solid #141619;
  }
  .uk-dropdown-width-2:not(.uk-dropdown-stack) {
    width: 400px;
  }
  .uk-dropdown-width-3:not(.uk-dropdown-stack) {
    width: 600px;
  }
  .uk-dropdown-width-4:not(.uk-dropdown-stack) {
    width: 800px;
  }
  .uk-dropdown-width-5:not(.uk-dropdown-stack) {
    width: 1000px;
  }
}
@media (max-width: 767px) {
  .uk-dropdown-grid > [class*='uk-width-'] {
    width: 100%;
  }
  .uk-dropdown-grid > [class*='uk-width-']:nth-child(n+2) {
    margin-top: 15px;
  }
}
.uk-dropdown-stack > .uk-dropdown-grid > [class*='uk-width-'] {
  width: 100%;
}
.uk-dropdown-stack > .uk-dropdown-grid > [class*='uk-width-']:nth-child(n+2) {
  margin-top: 15px;
}
.uk-dropdown-small {
  min-width: 150px;
  width: auto;
  padding: 5px;
  white-space: nowrap;
}
.uk-dropdown-small .uk-nav {
  margin: 0 -5px;
}
.uk-dropdown-navbar {
  margin-top: 0;
  background: #3a3f44;
  color: #c8c8c8;
}
.uk-open > .uk-dropdown-navbar {
  -webkit-animation: uk-slide-top-fixed 0.2s ease-in-out;
  animation: uk-slide-top-fixed 0.2s ease-in-out;
}
.uk-dropdown-scrollable {
  overflow-y: auto;
  max-height: 200px;
}
.uk-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  touch-action: cross-slide-y pinch-zoom double-tap-zoom;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.uk-modal.uk-open {
  opacity: 1;
}
.uk-modal-page,
.uk-modal-page body {
  overflow: hidden;
}
.uk-modal-dialog {
  position: relative;
  box-sizing: border-box;
  margin: 50px auto;
  padding: 20px;
  width: 600px;
  max-width: 100%;
  max-width: calc(100% - 20px);
  background: #272b30;
  opacity: 0;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: opacity 0.3s linear, -webkit-transform 0.3s ease-out;
  transition: opacity 0.3s linear, transform 0.3s ease-out;
}
@media (max-width: 767px) {
  .uk-modal-dialog {
    width: auto;
    margin: 10px auto;
  }
}
.uk-open .uk-modal-dialog {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.uk-modal-dialog > :not([class*='uk-modal-']):last-child {
  margin-bottom: 0;
}
.uk-modal-dialog > .uk-close:first-child {
  margin: -10px -10px 0 0;
  float: right;
}
.uk-modal-dialog > .uk-close:first-child + :not([class*='uk-modal-']) {
  margin-top: 0;
}
.uk-modal-dialog-lightbox {
  margin: 15px auto;
  padding: 0;
  max-width: 95%;
  max-width: calc(100% - 30px);
}
.uk-modal-dialog-lightbox > .uk-close:first-child {
  position: absolute;
  top: -12px;
  right: -12px;
  margin: 0;
  float: none;
}
@media (max-width: 767px) {
  .uk-modal-dialog-lightbox > .uk-close:first-child {
    top: -7px;
    right: -7px;
  }
}
.uk-modal-dialog-blank {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
.uk-modal-dialog-blank > .uk-close:first-child {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  margin: 0;
  float: none;
}
@media (min-width: 768px) {
  .uk-modal-dialog-large {
    width: 930px;
  }
}
@media (min-width: 1220px) {
  .uk-modal-dialog-large {
    width: 1130px;
  }
}
.uk-modal-header {
  margin-bottom: 15px;
}
.uk-modal-footer {
  margin-top: 15px;
}
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}
.uk-modal-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin-bottom: -10px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.uk-modal-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 25px;
  color: #ddd;
}
.uk-modal-spinner:after {
  content: "\f110";
  font-family: FontAwesome;
  -webkit-animation: uk-rotate 2s infinite linear;
  animation: uk-rotate 2s infinite linear;
}
.uk-offcanvas {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.uk-offcanvas.uk-active {
  display: block;
}
.uk-offcanvas-page {
  position: fixed;
  -webkit-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}
.uk-offcanvas-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1001;
  width: 270px;
  max-width: 100%;
  background: #333333;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  -ms-scroll-chaining: none;
}
.uk-offcanvas.uk-active .uk-offcanvas-bar.uk-offcanvas-bar-show {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.uk-offcanvas-bar-flip {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.uk-offcanvas .uk-panel {
  margin: 20px 15px;
  color: #777777;
}
.uk-offcanvas .uk-panel-title {
  color: #cccccc;
}
.uk-offcanvas .uk-panel a:not([class]) {
  color: #cccccc;
}
.uk-offcanvas .uk-panel a:not([class]):hover {
  color: #ffffff;
}
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
  touch-action: cross-slide-y pinch-zoom double-tap-zoom;
}
.uk-switcher > :not(.uk-active) {
  display: none;
}
.uk-text-small {
  font-size: 11px;
  line-height: 16px;
}
.uk-text-large {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
.uk-text-bold {
  font-weight: bold;
}
.uk-text-muted {
  color: #7a8288 !important;
}
.uk-text-primary {
  color: #7a8288 !important;
}
.uk-text-success {
  color: #62c462 !important;
}
.uk-text-warning {
  color: #f89406 !important;
}
.uk-text-danger {
  color: #ee5f5b !important;
}
.uk-text-contrast {
  color: #ffffff !important;
}
.uk-text-left {
  text-align: left !important;
}
.uk-text-right {
  text-align: right !important;
}
.uk-text-center {
  text-align: center !important;
}
.uk-text-justify {
  text-align: justify !important;
}
.uk-text-top {
  vertical-align: top !important;
}
.uk-text-middle {
  vertical-align: middle !important;
}
.uk-text-bottom {
  vertical-align: bottom !important;
}
@media (max-width: 959px) {
  .uk-text-center-medium {
    text-align: center !important;
  }
  .uk-text-left-medium {
    text-align: left !important;
  }
}
@media (max-width: 767px) {
  .uk-text-center-small {
    text-align: center !important;
  }
  .uk-text-left-small {
    text-align: left !important;
  }
}
.uk-text-nowrap {
  white-space: nowrap;
}
.uk-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.uk-text-break {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.uk-container {
  box-sizing: border-box;
  max-width: 980px;
  padding: 0 25px;
}
@media (min-width: 1220px) {
  .uk-container {
    max-width: 1200px;
    padding: 0 35px;
  }
}
.uk-container:before,
.uk-container:after {
  content: "";
  display: table;
}
.uk-container:after {
  clear: both;
}
.uk-container-center {
  margin-left: auto;
  margin-right: auto;
}
.uk-clearfix:before {
  content: "";
  display: table-cell;
}
.uk-clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.uk-nbfc {
  overflow: hidden;
}
.uk-nbfc-alt {
  display: table-cell;
  width: 10000px;
}
.uk-float-left {
  float: left;
}
.uk-float-right {
  float: right;
}
[class*='uk-float-'] {
  max-width: 100%;
}
[class*='uk-align-'] {
  display: block;
  margin-bottom: 15px;
}
.uk-align-left {
  margin-right: 15px;
  float: left;
}
.uk-align-right {
  margin-left: 15px;
  float: right;
}
@media (min-width: 768px) {
  .uk-align-medium-left {
    margin-right: 15px;
    float: left;
  }
  .uk-align-medium-right {
    margin-left: 15px;
    float: right;
  }
}
.uk-align-center {
  margin-left: auto;
  margin-right: auto;
}
.uk-vertical-align {
  font-size: 0.001px;
}
.uk-vertical-align:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.uk-vertical-align-middle,
.uk-vertical-align-bottom {
  display: inline-block;
  max-width: 100%;
  font-size: 1rem;
}
.uk-vertical-align-middle {
  vertical-align: middle;
}
.uk-vertical-align-bottom {
  vertical-align: bottom;
}
[class*='uk-height'] {
  box-sizing: border-box;
}
.uk-height-1-1 {
  height: 100%;
}
.uk-height-viewport {
  height: 100vh;
  min-height: 600px;
}
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}
.uk-responsive-width {
  max-width: 100% !important;
  height: auto;
}
.uk-responsive-height {
  max-height: 100%;
  width: auto;
}
.uk-margin {
  margin-bottom: 15px;
}
* + .uk-margin {
  margin-top: 15px;
}
.uk-margin-top {
  margin-top: 15px !important;
}
.uk-margin-bottom {
  margin-bottom: 15px !important;
}
.uk-margin-left {
  margin-left: 15px !important;
}
.uk-margin-right {
  margin-right: 15px !important;
}
.uk-margin-large {
  margin-bottom: 50px;
}
* + .uk-margin-large {
  margin-top: 50px;
}
.uk-margin-large-top {
  margin-top: 50px !important;
}
.uk-margin-large-bottom {
  margin-bottom: 50px !important;
}
.uk-margin-large-left {
  margin-left: 50px !important;
}
.uk-margin-large-right {
  margin-right: 50px !important;
}
.uk-margin-small {
  margin-bottom: 5px;
}
* + .uk-margin-small {
  margin-top: 5px;
}
.uk-margin-small-top {
  margin-top: 5px !important;
}
.uk-margin-small-bottom {
  margin-bottom: 5px !important;
}
.uk-margin-small-left {
  margin-left: 5px !important;
}
.uk-margin-small-right {
  margin-right: 5px !important;
}
.uk-margin-remove {
  margin: 0 !important;
}
.uk-margin-top-remove {
  margin-top: 0 !important;
}
.uk-margin-bottom-remove {
  margin-bottom: 0 !important;
}
.uk-padding-remove {
  padding: 0 !important;
}
.uk-padding-top-remove {
  padding-top: 0 !important;
}
.uk-padding-bottom-remove {
  padding-bottom: 0 !important;
}
.uk-padding-vertical-remove {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.uk-border-circle {
  border-radius: 50%;
}
.uk-border-rounded {
  border-radius: 5px;
}
.uk-heading-large {
  font-size: 36px;
  line-height: 42px;
}
@media (min-width: 768px) {
  .uk-heading-large {
    font-size: 52px;
    line-height: 64px;
  }
}
.uk-link-muted,
.uk-link-muted a {
  color: #c8c8c8;
}
.uk-link-muted:hover,
.uk-link-muted a:hover {
  color: #c8c8c8;
}
.uk-link-reset,
.uk-link-reset a,
.uk-link-reset:hover,
.uk-link-reset a:hover,
.uk-link-reset:focus,
.uk-link-reset a:focus {
  color: inherit;
  text-decoration: none;
}
.uk-scrollable-text {
  height: 300px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  resize: both;
}
.uk-scrollable-box {
  box-sizing: border-box;
  height: 170px;
  padding: 10px;
  border: 1px solid #141619;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both;
}
.uk-scrollable-box > :last-child {
  margin-bottom: 0;
}
.uk-overflow-hidden {
  overflow: hidden;
}
.uk-overflow-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.uk-overflow-container > :last-child {
  margin-bottom: 0;
}
.uk-position-absolute,
[class*='uk-position-top'],
[class*='uk-position-bottom'] {
  position: absolute !important;
}
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}
.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.uk-position-top-left {
  top: 0;
  left: 0;
}
.uk-position-top-right {
  top: 0;
  right: 0;
}
.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}
.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.uk-position-relative {
  position: relative !important;
}
.uk-position-z-index {
  z-index: 1;
}
.uk-display-block {
  display: block !important;
}
.uk-display-inline {
  display: inline !important;
}
.uk-display-inline-block {
  display: inline-block !important;
  max-width: 100%;
}
@media (min-width: 960px) {
  .uk-visible-small {
    display: none !important;
  }
  .uk-visible-medium {
    display: none !important;
  }
  .uk-hidden-large {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  .uk-visible-small {
    display: none !important;
  }
  .uk-visible-large {
    display: none !important ;
  }
  .uk-hidden-medium {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .uk-visible-medium {
    display: none !important;
  }
  .uk-visible-large {
    display: none !important;
  }
  .uk-hidden-small {
    display: none !important;
  }
}
.uk-hidden {
  display: none !important;
  visibility: hidden !important;
}
.uk-invisible {
  visibility: hidden !important;
}
.uk-visible-hover:hover .uk-hidden,
.uk-visible-hover:hover .uk-invisible {
  display: block !important;
  visibility: visible !important;
}
.uk-visible-hover-inline:hover .uk-hidden,
.uk-visible-hover-inline:hover .uk-invisible {
  display: inline-block !important;
  visibility: visible !important;
}
.uk-touch .uk-hidden-touch,
.uk-notouch .uk-hidden-notouch {
  display: none !important;
}
.uk-flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.uk-flex-inline {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.uk-flex > *,
.uk-flex-inline > * {
  -ms-flex-negative: 1;
}
.uk-flex-top {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.uk-flex-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.uk-flex-bottom {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.uk-flex-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.uk-flex-right {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.uk-flex-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.uk-flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.uk-flex-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.uk-flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.uk-flex-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.uk-flex-nowrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.uk-flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.uk-flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.uk-flex-wrap-top {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}
.uk-flex-wrap-middle {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
.uk-flex-wrap-bottom {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.uk-flex-wrap-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}
.uk-flex-wrap-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}
.uk-flex-order-first {
  -ms-flex-order: -1;
  -webkit-order: -1;
  order: -1;
}
.uk-flex-order-last {
  -ms-flex-order: 99;
  -webkit-order: 99;
  order: 99;
}
@media (min-width: 480px) {
  .uk-flex-order-first-small {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-small {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
@media (min-width: 768px) {
  .uk-flex-order-first-medium {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-medium {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
@media (min-width: 960px) {
  .uk-flex-order-first-large {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-large {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
@media (min-width: 1220px) {
  .uk-flex-order-first-xlarge {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-xlarge {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
.uk-flex-item-none {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.uk-flex-item-auto {
  -ms-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  -ms-flex-negative: 1;
}
.uk-flex-item-1 {
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.uk-contrast {
  color: #ffffff;
}
.uk-contrast a:not([class]),
.uk-contrast .uk-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.uk-contrast a:not([class]):hover,
.uk-contrast .uk-link:hover {
  color: #ffffff;
  text-decoration: underline;
}
.uk-contrast :not(pre) > code,
.uk-contrast :not(pre) > kbd,
.uk-contrast :not(pre) > samp {
  color: #ffffff;
}
.uk-contrast em {
  color: #ffffff;
}
.uk-contrast h1,
.uk-contrast h2,
.uk-contrast h3,
.uk-contrast h4,
.uk-contrast h5,
.uk-contrast h6 {
  color: #ffffff;
}
.uk-contrast hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-nav li > a,
.uk-contrast .uk-nav li > a:hover {
  text-decoration: none;
}
.uk-contrast .uk-nav-side > li > a {
  color: #ffffff;
}
.uk-contrast .uk-nav-side > li > a:hover,
.uk-contrast .uk-nav-side > li > a:focus {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.uk-contrast .uk-nav-side > li.uk-active > a {
  background: #ffffff;
  color: #444444;
}
.uk-contrast .uk-nav-side .uk-nav-header {
  color: #ffffff;
}
.uk-contrast .uk-nav-side .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-nav-side ul a {
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-nav-side ul a:hover {
  color: #ffffff;
}
.uk-contrast .uk-subnav > * > a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.uk-contrast .uk-subnav > * > a:hover,
.uk-contrast .uk-subnav > * > a:focus {
  color: #ffffff;
  text-decoration: none;
}
.uk-contrast .uk-subnav > .uk-active > a {
  color: #ffffff;
}
.uk-contrast .uk-subnav-line > :nth-child(n+2):before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-subnav-pill > * > a:hover,
.uk-contrast .uk-subnav-pill > * > a:focus {
  background: rgba(255, 255, 255, 0.7);
  color: #444444;
  text-decoration: none;
}
.uk-contrast .uk-subnav-pill > .uk-active > a {
  background: #ffffff;
  color: #444444;
}
.uk-contrast .uk-tab {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-tab > li > a {
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-tab > li > a:hover,
.uk-contrast .uk-tab > li > a:focus,
.uk-contrast .uk-tab > li.uk-open > a {
  border-color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.7);
  color: #444444;
  text-decoration: none;
}
.uk-contrast .uk-tab > li.uk-active > a {
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: transparent;
  background: #ffffff;
  color: #444444;
}
.uk-contrast .uk-tab-center {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-tab-grid:before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-list-line > li:nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-form select,
.uk-contrast .uk-form textarea,
.uk-contrast .uk-form input:not([type]),
.uk-contrast .uk-form input[type="text"],
.uk-contrast .uk-form input[type="password"],
.uk-contrast .uk-form input[type="datetime"],
.uk-contrast .uk-form input[type="datetime-local"],
.uk-contrast .uk-form input[type="date"],
.uk-contrast .uk-form input[type="month"],
.uk-contrast .uk-form input[type="time"],
.uk-contrast .uk-form input[type="week"],
.uk-contrast .uk-form input[type="number"],
.uk-contrast .uk-form input[type="email"],
.uk-contrast .uk-form input[type="url"],
.uk-contrast .uk-form input[type="search"],
.uk-contrast .uk-form input[type="tel"],
.uk-contrast .uk-form input[type="color"] {
  border-color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.8);
  color: #444444;
  background-clip: padding-box;
}
.uk-contrast .uk-form select:focus,
.uk-contrast .uk-form textarea:focus,
.uk-contrast .uk-form input:not([type]):focus,
.uk-contrast .uk-form input[type="text"]:focus,
.uk-contrast .uk-form input[type="password"]:focus,
.uk-contrast .uk-form input[type="datetime"]:focus,
.uk-contrast .uk-form input[type="datetime-local"]:focus,
.uk-contrast .uk-form input[type="date"]:focus,
.uk-contrast .uk-form input[type="month"]:focus,
.uk-contrast .uk-form input[type="time"]:focus,
.uk-contrast .uk-form input[type="week"]:focus,
.uk-contrast .uk-form input[type="number"]:focus,
.uk-contrast .uk-form input[type="email"]:focus,
.uk-contrast .uk-form input[type="url"]:focus,
.uk-contrast .uk-form input[type="search"]:focus,
.uk-contrast .uk-form input[type="tel"]:focus,
.uk-contrast .uk-form input[type="color"]:focus {
  border-color: #ffffff;
  background: #ffffff;
  color: #444444;
}
.uk-contrast .uk-form :-ms-input-placeholder {
  color: rgba(68, 68, 68, 0.7) !important;
}
.uk-contrast .uk-form ::-moz-placeholder {
  color: rgba(68, 68, 68, 0.7);
}
.uk-contrast .uk-form ::-webkit-input-placeholder {
  color: rgba(68, 68, 68, 0.7);
}
.uk-contrast .uk-button {
  color: #444444;
  background: #ffffff;
}
.uk-contrast .uk-button:hover,
.uk-contrast .uk-button:focus {
  background-color: rgba(255, 255, 255, 0.8);
  color: #444444;
}
.uk-contrast .uk-button:active,
.uk-contrast .uk-button.uk-active {
  background-color: rgba(255, 255, 255, 0.7);
  color: #444444;
}
.uk-contrast .uk-button-primary {
  background-color: #7a8288;
  color: #ffffff;
}
.uk-contrast .uk-button-primary:hover,
.uk-contrast .uk-button-primary:focus {
  background-color: #62686d;
  color: #ffffff;
}
.uk-contrast .uk-button-primary:active,
.uk-contrast .uk-button-primary.uk-active {
  background-color: #62686d;
  color: #ffffff;
}
.uk-contrast .uk-icon-hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-icon-hover:hover {
  color: #ffffff;
}
.uk-contrast .uk-icon-button {
  background: #ffffff;
  color: #444444;
}
.uk-contrast .uk-icon-button:hover,
.uk-contrast .uk-icon-button:focus {
  background-color: rgba(255, 255, 255, 0.8);
  color: #444444;
}
.uk-contrast .uk-icon-button:active {
  background-color: rgba(255, 255, 255, 0.7);
  color: #444444;
}
.uk-contrast .uk-text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
.uk-contrast .uk-text-primary {
  color: #7a8288 !important;
}
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
.uk-dotnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-top: -15px;
  padding: 0;
  list-style: none;
}
.uk-dotnav > * {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  padding-left: 15px;
  margin-top: 15px;
}
.uk-dotnav:before,
.uk-dotnav:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-dotnav:after {
  clear: both;
}
.uk-dotnav > * {
  float: left;
}
.uk-dotnav > * > * {
  display: block;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.1);
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.uk-dotnav > * > :hover,
.uk-dotnav > * > :focus {
  background: rgba(50, 50, 50, 0.4);
  outline: none;
}
.uk-dotnav > * > :active {
  background: rgba(50, 50, 50, 0.6);
}
.uk-dotnav > .uk-active > * {
  background: rgba(50, 50, 50, 0.4);
}
.uk-dotnav-contrast > * > * {
  background: rgba(255, 255, 255, 0.4);
}
.uk-dotnav-contrast > * > :hover,
.uk-dotnav-contrast > * > :focus {
  background: rgba(255, 255, 255, 0.7);
}
.uk-dotnav-contrast > * > :active {
  background: rgba(255, 255, 255, 0.9);
}
.uk-dotnav-contrast > .uk-active > * {
  background: rgba(255, 255, 255, 0.9);
}
.uk-dotnav-vertical {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.uk-dotnav-vertical > * {
  float: none;
}
.uk-slidenav {
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: rgba(50, 50, 50, 0.4);
  font-size: 60px;
  text-align: center;
}
.uk-slidenav:hover,
.uk-slidenav:focus {
  outline: none;
  text-decoration: none;
  color: rgba(50, 50, 50, 0.7);
  cursor: pointer;
}
.uk-slidenav:active {
  color: rgba(50, 50, 50, 0.9);
}
.uk-slidenav-previous:before {
  content: "\f104";
  font-family: FontAwesome;
}
.uk-slidenav-next:before {
  content: "\f105";
  font-family: FontAwesome;
}
.uk-slidenav-position {
  position: relative;
}
.uk-slidenav-position .uk-slidenav {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -30px;
}
.uk-slidenav-position:hover .uk-slidenav {
  display: block;
}
.uk-slidenav-position .uk-slidenav-previous {
  left: 20px;
}
.uk-slidenav-position .uk-slidenav-next {
  right: 20px;
}
.uk-slidenav-contrast {
  color: rgba(255, 255, 255, 0.5);
}
.uk-slidenav-contrast:hover,
.uk-slidenav-contrast:focus {
  color: rgba(255, 255, 255, 0.7);
}
.uk-slidenav-contrast:active {
  color: rgba(255, 255, 255, 0.9);
}
.uk-form input[type="radio"],
.uk-form input[type="checkbox"] {
  display: inline-block;
  height: 14px;
  width: 14px;
  border: 1px solid #aaaaaa;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: 0;
  background: transparent;
}
.uk-form input[type="radio"] {
  border-radius: 50%;
}
.uk-form input[type=radio]:before,
.uk-form input[type=checkbox]:before {
  display: block;
}
.uk-form input[type=radio]:checked:before {
  content: '';
  width: 8px;
  height: 8px;
  margin: 2px auto 0;
  border-radius: 50%;
  background: #00a8e6;
}
.uk-form input[type=checkbox]:checked:before,
.uk-form input[type=checkbox]:indeterminate:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  line-height: 12px;
  color: #00a8e6;
}
.uk-form input[type=checkbox]:indeterminate:before {
  content: "\f068";
}
.uk-form input[type=radio]:disabled,
.uk-form input[type=checkbox]:disabled {
  border-color: #dddddd;
}
.uk-form input[type=radio]:disabled:checked:before {
  background-color: #6480c1;
}
.uk-form input[type=checkbox]:disabled:checked:before,
.uk-form input[type=checkbox]:disabled:indeterminate:before {
  color: #6480c1;
}
.uk-form-file {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
.uk-form-file input[type="file"] {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  left: 0;
  font-size: 500px;
}
.uk-form-password {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.uk-form-password-toggle {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -6px;
  font-size: 13px;
  line-height: 13px;
  color: #7a8288;
}
.uk-form-password-toggle:hover {
  color: #7a8288;
  text-decoration: none;
}
.uk-form-password > input {
  padding-right: 50px !important;
}
.uk-form-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
.uk-form-select select {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  left: 0;
  -webkit-appearance: none;
}
.uk-placeholder {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px dashed #141619;
  background: #fafafa;
  color: #c8c8c8;
}
* + .uk-placeholder {
  margin-top: 15px;
}
.uk-placeholder > :last-child {
  margin-bottom: 0;
}
.uk-placeholder-large {
  padding-top: 80px;
  padding-bottom: 80px;
}
.uk-progress {
  box-sizing: border-box;
  height: 20px;
  margin-bottom: 15px;
  background: #eeeeee;
  overflow: hidden;
  line-height: 20px;
}
* + .uk-progress {
  margin-top: 15px;
}
.uk-progress-bar {
  width: 0;
  height: 100%;
  background: #7a8288;
  float: left;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}
.uk-progress-mini {
  height: 6px;
}
.uk-progress-small {
  height: 12px;
}
.uk-progress-success .uk-progress-bar {
  background-color: #62c462;
}
.uk-progress-warning .uk-progress-bar {
  background-color: #f89406;
}
.uk-progress-danger .uk-progress-bar {
  background-color: #ee5f5b;
}
.uk-progress-striped .uk-progress-bar {
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 30px 30px;
}
.uk-progress-striped.uk-active .uk-progress-bar {
  -webkit-animation: uk-progress-bar-stripes 2s linear infinite;
  animation: uk-progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes uk-progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}
@keyframes uk-progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}
.uk-accordion-title {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 5px 15px;
  background: #eeeeee;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}
.uk-accordion-content {
  padding: 0 15px 15px 15px;
}
.uk-accordion-content:before,
.uk-accordion-content:after {
  content: "";
  display: table;
}
.uk-accordion-content:after {
  clear: both;
}
.uk-accordion-content > :last-child {
  margin-bottom: 0;
}
.uk-autocomplete {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle;
}
.uk-nav-autocomplete > li > a {
  color: #c8c8c8;
}
.uk-nav-autocomplete > li.uk-active > a {
  background: #7a8288;
  color: #ffffff;
  outline: none;
}
.uk-nav-autocomplete .uk-nav-header {
  color: #7a8288;
}
.uk-nav-autocomplete .uk-nav-divider {
  border-top: 1px solid #141619;
}
.uk-datepicker {
  z-index: 1050;
  width: auto;
  -webkit-animation: uk-fade 0.2s ease-in-out;
  animation: uk-fade 0.2s ease-in-out;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.uk-datepicker-nav {
  margin-bottom: 15px;
  text-align: center;
  line-height: 20px;
}
.uk-datepicker-nav:before,
.uk-datepicker-nav:after {
  content: "";
  display: table;
}
.uk-datepicker-nav:after {
  clear: both;
}
.uk-datepicker-nav a {
  color: #c8c8c8;
  text-decoration: none;
}
.uk-datepicker-nav a:hover {
  color: #c8c8c8;
}
.uk-datepicker-previous {
  float: left;
}
.uk-datepicker-next {
  float: right;
}
.uk-datepicker-previous:after,
.uk-datepicker-next:after {
  width: 20px;
  font-family: FontAwesome;
}
.uk-datepicker-previous:after {
  content: "\f053";
}
.uk-datepicker-next:after {
  content: "\f054";
}
.uk-datepicker-table {
  width: 100%;
}
.uk-datepicker-table th,
.uk-datepicker-table td {
  padding: 2px;
}
.uk-datepicker-table th {
  font-size: 12px;
}
.uk-datepicker-table a {
  display: block;
  width: 26px;
  line-height: 24px;
  text-align: center;
  color: #c8c8c8;
  text-decoration: none;
}
a.uk-datepicker-table-muted {
  color: #7a8288;
}
.uk-datepicker-table a:hover,
.uk-datepicker-table a:focus {
  background-color: #dddddd;
  color: #c8c8c8;
  outline: none;
}
.uk-datepicker-table a:active {
  background-color: #cccccc;
  color: #c8c8c8;
}
.uk-datepicker-table a.uk-active {
  background: #7a8288;
  color: #ffffff;
}
.uk-htmleditor-navbar {
  background: #eeeeee;
}
.uk-htmleditor-navbar:before,
.uk-htmleditor-navbar:after {
  content: "";
  display: table;
}
.uk-htmleditor-navbar:after {
  clear: both;
}
.uk-htmleditor-navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}
.uk-htmleditor-navbar-nav > li {
  float: left;
}
.uk-htmleditor-navbar-nav > li > a {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  color: #c8c8c8;
  font-size: 11px;
  cursor: pointer;
}
.uk-htmleditor-navbar-nav > li:hover > a,
.uk-htmleditor-navbar-nav > li > a:focus {
  background-color: #f5f5f5;
  color: #c8c8c8;
  outline: none;
}
.uk-htmleditor-navbar-nav > li > a:active {
  background-color: #dddddd;
  color: #c8c8c8;
}
.uk-htmleditor-navbar-nav > li.uk-active > a {
  background-color: #f5f5f5;
  color: #c8c8c8;
}
.uk-htmleditor-navbar-flip {
  float: right;
}
[data-mode='split'] .uk-htmleditor-button-code,
[data-mode='split'] .uk-htmleditor-button-preview {
  display: none;
}
.uk-htmleditor-content {
  border-left: 1px solid #141619;
  border-right: 1px solid #141619;
  border-bottom: 1px solid #141619;
  background: #272b30;
}
.uk-htmleditor-content:before,
.uk-htmleditor-content:after {
  content: "";
  display: table;
}
.uk-htmleditor-content:after {
  clear: both;
}
.uk-htmleditor-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
}
.uk-htmleditor-fullscreen .uk-htmleditor-content {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
}
.uk-htmleditor-fullscreen .uk-icon-expand:before {
  content: "\f066";
}
.uk-htmleditor-code,
.uk-htmleditor-preview {
  box-sizing: border-box;
}
.uk-htmleditor-preview {
  padding: 20px;
  overflow-y: scroll;
  position: relative;
}
[data-mode='tab'][data-active-tab='code'] .uk-htmleditor-preview,
[data-mode='tab'][data-active-tab='preview'] .uk-htmleditor-code {
  display: none;
}
[data-mode='split'] .uk-htmleditor-code,
[data-mode='split'] .uk-htmleditor-preview {
  float: left;
  width: 50%;
}
[data-mode='split'] .uk-htmleditor-code {
  border-right: 1px solid #eeeeee;
}
.uk-htmleditor-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.uk-htmleditor .CodeMirror {
  padding: 10px;
  box-sizing: border-box;
}
.uk-slider {
  position: relative;
  z-index: 0;
  touch-action: pan-y;
}
.uk-slider:not(.uk-grid) {
  margin: 0;
  padding: 0;
  list-style: none;
}
.uk-slider > * {
  position: absolute;
  top: 0;
  left: 0;
}
.uk-slider-container {
  overflow: hidden;
}
.uk-slider:not(.uk-drag) {
  -webkit-transition: -webkit-transform 200ms linear;
  transition: transform 200ms linear;
}
.uk-slider.uk-drag {
  cursor: col-resize;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.uk-slider a,
.uk-slider img {
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
.uk-slider img {
  pointer-events: none;
}
.uk-slider-fullscreen,
.uk-slider-fullscreen > li {
  height: 100vh;
}
.uk-slideshow {
  position: relative;
  z-index: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  touch-action: pan-y;
}
.uk-slideshow > li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.uk-slideshow > .uk-active {
  z-index: 10;
  opacity: 1;
}
.uk-slideshow > li > img {
  visibility: hidden;
}
[data-uk-slideshow-slide] {
  cursor: pointer;
}
.uk-slideshow-fullscreen,
.uk-slideshow-fullscreen > li {
  height: 100vh;
}
.uk-slideshow-fade-in {
  -webkit-animation: uk-fade 0.5s linear;
  animation: uk-fade 0.5s linear;
}
.uk-slideshow-fade-out {
  -webkit-animation: uk-fade 0.5s linear reverse;
  animation: uk-fade 0.5s linear reverse;
}
.uk-slideshow-scroll-forward-in {
  -webkit-animation: uk-slide-right 0.5s ease-in-out;
  animation: uk-slide-right 0.5s ease-in-out;
}
.uk-slideshow-scroll-forward-out {
  -webkit-animation: uk-slide-left 0.5s ease-in-out reverse;
  animation: uk-slide-left 0.5s ease-in-out reverse;
}
.uk-slideshow-scroll-backward-in {
  -webkit-animation: uk-slide-left 0.5s ease-in-out;
  animation: uk-slide-left 0.5s ease-in-out;
}
.uk-slideshow-scroll-backward-out {
  -webkit-animation: uk-slide-right 0.5s ease-in-out reverse;
  animation: uk-slide-right 0.5s ease-in-out reverse;
}
.uk-slideshow-scale-out {
  -webkit-animation: uk-fade-scale-15 0.5s ease-in-out reverse;
  animation: uk-fade-scale-15 0.5s ease-in-out reverse;
}
.uk-slideshow-swipe-forward-in {
  -webkit-animation: uk-slide-left-33 0.5s ease-in-out;
  animation: uk-slide-left-33 0.5s ease-in-out;
}
.uk-slideshow-swipe-forward-out {
  -webkit-animation: uk-slide-left 0.5s ease-in-out reverse;
  animation: uk-slide-left 0.5s ease-in-out reverse;
}
.uk-slideshow-swipe-backward-in {
  -webkit-animation: uk-slide-right-33 0.5s ease-in-out;
  animation: uk-slide-right-33 0.5s ease-in-out;
}
.uk-slideshow-swipe-backward-out {
  -webkit-animation: uk-slide-right 0.5s ease-in-out reverse;
  animation: uk-slide-right 0.5s ease-in-out reverse;
}
.uk-slideshow-swipe-forward-in:before,
.uk-slideshow-swipe-backward-in:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  -webkit-animation: uk-fade 0.5s ease-in-out reverse;
  animation: uk-fade 0.5s ease-in-out reverse;
}
.uk-notify {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  box-sizing: border-box;
  width: 350px;
}
.uk-notify-top-right,
.uk-notify-bottom-right {
  left: auto;
  right: 10px;
}
.uk-notify-top-center,
.uk-notify-bottom-center {
  left: 50%;
  margin-left: -175px;
}
.uk-notify-bottom-left,
.uk-notify-bottom-right,
.uk-notify-bottom-center {
  top: auto;
  bottom: 10px;
}
@media (max-width: 479px) {
  .uk-notify {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0;
  }
}
.uk-notify-message {
  position: relative;
  margin-bottom: 10px;
  padding: 15px;
  background: #444444;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}
.uk-notify-message > .uk-close {
  visibility: hidden;
  float: right;
}
.uk-notify-message:hover > .uk-close {
  visibility: visible;
}
.uk-notify-message-primary {
  background: #ebf7fd;
  color: #2d7091;
}
.uk-notify-message-success {
  background: #f2fae3;
  color: #659f13;
}
.uk-notify-message-warning {
  background: #fffceb;
  color: #e28327;
}
.uk-notify-message-danger {
  background: #fff1f0;
  color: #d85030;
}
.uk-search {
  display: inline-block;
  position: relative;
  margin: 0;
}
.uk-search:before {
  content: "\f002";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-family: FontAwesome;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2);
}
.uk-search-field::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.uk-search-field::-webkit-search-cancel-button,
.uk-search-field::-webkit-search-decoration {
  -webkit-appearance: none;
}
.uk-search-field::-ms-clear {
  display: none;
}
.uk-search-field::-moz-placeholder {
  opacity: 1;
}
.uk-search-field {
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  color: #c8c8c8;
  -webkit-appearance: none;
  width: 120px;
  height: 30px;
  padding: 0 0 0 30px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  vertical-align: middle;
}
.uk-search-field:-ms-input-placeholder {
  color: #7a8288 !important;
}
.uk-search-field::-moz-placeholder {
  color: #7a8288;
}
.uk-search-field::-webkit-input-placeholder {
  color: #7a8288;
}
.uk-search-field:focus {
  outline: 0;
}
.uk-search-field:focus,
.uk-search.uk-active .uk-search-field {
  width: 180px;
}
.uk-dropdown-search {
  width: 300px;
  margin-top: 0;
  background: #3a3f44;
  color: #c8c8c8;
}
.uk-open > .uk-dropdown-search {
  -webkit-animation: uk-slide-top-fixed 0.2s ease-in-out;
  animation: uk-slide-top-fixed 0.2s ease-in-out;
}
.uk-navbar-flip .uk-dropdown-search {
  margin-top: 5px;
  margin-right: -15px;
}
.uk-nav-search > li > a {
  color: #c8c8c8;
}
.uk-nav-search > li.uk-active > a {
  background: #7a8288;
  color: #ffffff;
  outline: none;
}
.uk-nav-search .uk-nav-header {
  color: #7a8288;
}
.uk-nav-search .uk-nav-divider {
  border-top: 1px solid #141619;
}
.uk-nav-search ul a {
  color: #ffffff;
}
.uk-nav-search ul a:hover {
  color: #ffffff;
}
.uk-offcanvas .uk-search {
  display: block;
  margin: 20px 15px;
}
.uk-offcanvas .uk-search:before {
  color: #777777;
}
.uk-offcanvas .uk-search-field {
  width: 100%;
  border-color: rgba(0, 0, 0, 0);
  background: #1a1a1a;
  color: #cccccc;
}
.uk-offcanvas .uk-search-field:-ms-input-placeholder {
  color: #777777 !important;
}
.uk-offcanvas .uk-search-field::-moz-placeholder {
  color: #777777;
}
.uk-offcanvas .uk-search-field::-webkit-input-placeholder {
  color: #777777;
}
.uk-nestable {
  padding: 0;
  list-style: none;
}
.uk-nestable a,
.uk-nestable img {
  -webkit-touch-callout: none;
}
.uk-nestable-list {
  margin: 0;
  padding-left: 40px;
  list-style: none;
}
.uk-nestable-item {
  touch-action: none;
}
.uk-nestable-item + .uk-nestable-item {
  margin-top: 10px;
}
.uk-nestable-list:not(.uk-nestable-dragged) > .uk-nestable-item:first-child {
  margin-top: 10px;
}
.uk-nestable-dragged {
  position: absolute;
  z-index: 1050;
  pointer-events: none;
  padding-left: 0;
}
.uk-nestable-placeholder {
  position: relative;
}
.uk-nestable-placeholder > * {
  opacity: 0;
}
.uk-nestable-placeholder:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px dashed #141619;
  opacity: 1;
}
.uk-nestable-empty {
  min-height: 30px;
}
.uk-nestable-handle {
  touch-action: none;
}
.uk-nestable-handle:hover {
  cursor: move;
}
.uk-nestable-moving,
.uk-nestable-moving * {
  cursor: move;
}
[data-nestable-action='toggle'] {
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.uk-nestable-toggle {
  display: inline-block;
  visibility: hidden;
}
.uk-nestable-toggle:after {
  content: "\f147";
  font-family: FontAwesome;
}
.uk-parent > :not(.uk-nestable-list) .uk-nestable-toggle {
  visibility: visible;
}
.uk-collapsed .uk-nestable-list {
  display: none;
}
.uk-collapsed .uk-nestable-toggle:after {
  content: "\f196";
}
.uk-nestable-panel {
  padding: 5px;
  background: #f5f5f5;
}
.uk-sortable {
  position: relative;
}
.uk-sortable > * {
  touch-action: none;
}
.uk-sortable a,
.uk-sortable img {
  -webkit-touch-callout: none;
}
.uk-sortable > :last-child {
  margin-bottom: 0;
}
.uk-sortable-dragged {
  position: absolute;
  z-index: 1050;
  pointer-events: none;
}
.uk-sortable-placeholder {
  opacity: 0;
}
.uk-sortable-empty {
  min-height: 30px;
}
.uk-sortable-handle {
  touch-action: none;
}
.uk-sortable-handle:hover {
  cursor: move;
}
.uk-sortable-moving,
.uk-sortable-moving * {
  cursor: move;
}
[data-uk-sticky].uk-active {
  z-index: 980;
  box-sizing: border-box;
}
.uk-sticky-placeholder > * {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
[data-uk-sticky][class*='uk-animation-'] {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
[data-uk-sticky].uk-animation-reverse {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
}
.uk-tooltip {
  display: none;
  position: absolute;
  z-index: 1030;
  box-sizing: border-box;
  max-width: 200px;
  padding: 5px 8px;
  background: #333333;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  line-height: 18px;
}
.uk-tooltip:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 5px dashed #333333;
}
.uk-tooltip-top:after,
.uk-tooltip-top-left:after,
.uk-tooltip-top-right:after {
  bottom: -5px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #333333;
}
.uk-tooltip-bottom:after,
.uk-tooltip-bottom-left:after,
.uk-tooltip-bottom-right:after {
  top: -5px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #333333;
}
.uk-tooltip-top:after,
.uk-tooltip-bottom:after {
  left: 50%;
  margin-left: -5px;
}
.uk-tooltip-top-left:after,
.uk-tooltip-bottom-left:after {
  left: 10px;
}
.uk-tooltip-top-right:after,
.uk-tooltip-bottom-right:after {
  right: 10px;
}
.uk-tooltip-left:after {
  right: -5px;
  top: 50%;
  margin-top: -5px;
  border-left-style: solid;
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #333333;
}
.uk-tooltip-right:after {
  left: -5px;
  top: 50%;
  margin-top: -5px;
  border-right-style: solid;
  border-left: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: #333333;
}
